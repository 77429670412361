import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';

const ThumbnailList = (props) => (
  <div className="level-item">
    <figure className={`image ${props.params.class || ''}`}>
      <Image media={props.fields.image} />
      <span className="highlight-spot"></span>
    </figure>
  </div>
);

export default ThumbnailList;
