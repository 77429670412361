import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import UniversalHeader from './UniversalHeader';
import Navigation from './Navigation';
import Footer from './Footer';
import ChatSFHC from './components/custom/ChatSFHC/ChatSFHC';

import config from './common/config';

import not_found from './assets/graphics/404@2x.png';
import './_sass/main.scss';

// Renders a route-not-found message when no route is available from Sitecore
// The JSS equivalent of a 404 Not Found page.

// This is invoked from RouteHandler when Sitecore returns no valid route data.
// The NotFound component receives the Layout Service Context data, but no route data.
// This can be used to power parts of your site, such as navigation, from LS context additions
// without losing the ability to render them on your 404 pages :)

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chat: false,
      onlineSupport: false,
      utcOffset: config.utcOffset,
      prevScrollpos:
        typeof window === 'undefined' ? 0 : window.pageYOffset,
      visible: true,
      isActiveProfile: false,
      headerLogin: {},
      isProfileEnabled: false,
    };

    /*
    Bind our childHandler function to this context
    that will get called from our Child component
    */
    this.childHandler = this.childHandler.bind(this);
    this.calcTime = this.calcTime.bind(this);
    this.calcOnlineStatus = this.calcOnlineStatus.bind(this);
    this.getOnlineStatus = this.getOnlineStatus.bind(this);
    this.setHeaderLogin = this.setHeaderLogin.bind(this);
  }

  /*
    Return chat status from child
  */
  childHandler(dataFromChild) {
    if (dataFromChild !== this.state.chat) {
      this.setState({
        chat: !this.state.chat,
      });
    }
  }

  // given an offset value, gets current time
  calcTime(offset) {
    let d = new Date();
    let utc = d.getTime() + d.getTimezoneOffset() * 60000;
    let nd = new Date(utc + 3600000 * offset);
    return nd;
  }

  // given a datetime, will determine if it falls within current business hours as defined in the function
  calcOnlineStatus(nd) {
    let isOnline =
      nd.getDay() >= 1 &&
      nd.getDay() <= 5 &&
      nd.getHours() >= 6 &&
      nd.getHours() < 18; // M-F 6am-6pm
    if (!isOnline && nd.getDay() === 6) {
      // Saturdays 8am-12pm
      isOnline = nd.getHours() >= 8 && nd.getHours() < 12;
    }
    return isOnline;
  }

  getOnlineStatus() {
    const getLocalTime = this.calcTime(this.state.utcOffset);
    return this.calcOnlineStatus(getLocalTime);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.setState({
      onlineSupport: this.getOnlineStatus(),
    });
    this.getOnlineStatusTimer = setInterval(() => {
      if (this.getOnlineStatus() !== this.state.onlineSupport) {
        this.setState({
          onlineSupport: !this.state.onlineSupport,
        });
      }
    }, 120000); // check set to 2 minutes
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.getOnlineStatusTimer);
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };

  handleProfileToggle = () => {
    const { isActiveProfile } = this.state;
    const currentState = isActiveProfile;
    this.setState({
      isActiveProfile: !currentState,
    });
  };

  // pass api call from Universal Header to Navigation component
  setHeaderLogin = (headerLogin, isProfileEnabled) => {
    this.setState({
      headerLogin,
      isProfileEnabled,
    });
  };

  render() {
    const { route, currentUser, privatePath } = this.props;
    const {
      chat,
      onlineSupport,
      isActiveProfile,
      visible,
      headerLogin,
      isProfileEnabled,
    } = this.state;

    return (
      <React.Fragment>
        {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
        <Helmet>
          <title>404 Not Found</title>
        </Helmet>
        <a
          id="skiplink"
          className="is-sr-only-focusable"
          href="#skipToContent"
        >
          <div className="container">
            <span className="skiplink-text">
              Skip to main content
            </span>
          </div>
        </a>
        <header className="hero hero--default">
          <div
            className={`sticky ${
              !visible ? 'is-scrolling-down' : ''
            } ${
              typeof window !== 'undefined' &&
              window.pageYOffset !== 0
                ? 'is-scrolling'
                : ''
            }
              ${isActiveProfile ? 'is-profile-open' : ''}
            `}
          >
            <UniversalHeader
              currentUser={currentUser}
              isActiveProfile={isActiveProfile}
              onChildClick={this.handleProfileToggle}
              onHeaderLoginMounted={this.setHeaderLogin}
              headerLogin={headerLogin}
              isProfileEnabled={isProfileEnabled}
              privatePath={privatePath}
            />
            {/* Hero head: will stick at the top */}
            <div className="hero-head">
              <Navigation
                onlineSupport={onlineSupport.toString()}
                chat={chat.toString()}
                serviceRequests="false"
                currentUser={currentUser}
                isActiveProfile={isActiveProfile}
                onChildClick={this.handleProfileToggle}
                headerLogin={headerLogin}
                isProfileEnabled={isProfileEnabled}
                privatePath={privatePath}
              />
            </div>
          </div>
        </header>
        <main className="site__content">
          <div id="skipToContent" tabIndex="-1" />
          <section className="section section__not-found">
            <div className="container">
              <div className="not-found">
                <figure className="image not-found__image">
                  <img src={not_found} alt="page 404 not found" />
                </figure>
                <h1 className="title is-1">
                  Sorry, the page you're looking for can't be found .
                  . . we can't figure out why
                </h1>
                <p>
                  It might be an incorrect address or the page might
                  have moved.
                </p>
                <Link
                  to="/home"
                  className="sdw__button sdw__button--cochlear-yellow sdw__button-back"
                >
                  Head back home
                </Link>
              </div>
            </div>
          </section>
        </main>
        <Footer
          route={route}
          onlineSupport={onlineSupport.toString()}
          chat={chat.toString()}
          serviceRequests="false"
        />
        <ChatSFHC action={this.childHandler} />
      </React.Fragment>
    );
  }
}

export default NotFound;
