import React from 'react';
import PropTypes from 'prop-types';

const ArticleDate = (props) => {
  const date = new Date(props.date);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return (
    <span className="article__date">
      <span className="article__date__label">{props.label}&nbsp;</span>{day}&nbsp;{monthNames[month]}<span className="article__date__year">&nbsp;{year}</span>
    </span>  
  )
};

// Specifies the default values for props:
ArticleDate.defaultProps = {
  date: '',
  label: '',
};

ArticleDate.propTypes = {
  date: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ArticleDate