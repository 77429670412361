import React from 'react';
import { Placeholder, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import config from './../../common/config'

const { country, lang } = config;
/**
 * This is a single tab within the tabs sample component. These are added to the tabs placeholder.
 * This component demonstrates conditionally altering rendering when in the Sitecore Experience Editor to improve
 * author experience.
 */
const TabsItem = (props, rendering) => (
  <React.Fragment>
    {/*
      When we're editing the tabs we stack each tab vertically,
      which means there's no regular tab titles rendered.
      So we conditionally render the tab title here, when editing. */}
    {props.sitecoreContext &&
      props.sitecoreContext.pageEditing && <Text tag="h5" field={props.fields.title} />}

    {/* React.Fragment tells React to not use any wrapping tag for the component */}
    <div className="tabs__content__list">
      <Placeholder 
        rendering={props.rendering} 
        name="jss-TabsItem"
        renderEach={(component, index) => {

          component.props.params.chat = props.params.chat;
          component.props.params.serviceRequests = props.params.serviceRequests;
          component.props.params.onlineSupport = props.params.onlineSupport;
          if ( index >= 5 && component.props.rendering.componentName === "ArticlesListing") return null
          if ( index >= 3 && component.props.rendering.componentName === "ArticlesListing") {
            component.props.params.isHiddenMobile = true;
          }
          return (
            <React.Fragment key={index}>
              {component}
            </React.Fragment>
          )          
        }}
        
      />
    </div>
    {
      props.fields?.internalLink?.value?.href && 
      <div className="tabs__content__footer">
        <Link data-ecategory="Articles Listing" to={`/${country}/${lang}${props.fields.internalLink.value.href}`}>
          {props.fields.internalLink.value.text}
        </Link>
      </div>
    }
  </React.Fragment>
);

export default withSitecoreContext()(TabsItem);
