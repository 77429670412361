import React from 'react';
import PropTypes from 'prop-types'; 
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

/**
 * General component description in JSDoc format. Markdown is *supported*.
 */
const ArticlesAllSection = (props) => {  
  const { params, rendering } = props;    
  return (
    <div className={params.wrapperClass}>
      <div className="columns">
        <div className="column is-full">
          <Placeholder 
            rendering={rendering} 
            name="jss-ArticlesAllSection"
            renderEach={(component, index) => {
              return (
                <React.Fragment key={index}>
                  {component}
                </React.Fragment>
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}

ArticlesAllSection.propTypes = {
  fields: PropTypes.shape({
    wrapperClass: PropTypes.shape({
      value: PropTypes.string
    })
  })
};

export default ArticlesAllSection;
