import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import MyDeviceList from './../../MyDeviceList/MyDeviceList';

class Step1 extends React.Component {
  render() {
    const {
      handleSelect,
      profileIsLoading,
      selectedProcessor,
      title,
      helpBtnMobile,
      helpBtnDesktop,
      currentUser,
      userId,
    } = this.props;
    return (
      <li className="step__panel">
        <Text tag="h2" field={title} className="title is-3" />
        {/* {currentStep === 1 &&  */}
        <div className="step__panel__inner">
          <MyDeviceList
            currentUser={currentUser}
            handleSelect={handleSelect}
            profileIsLoading={profileIsLoading}
            selectedProcessor={selectedProcessor}
            userId={userId}
            helpBtnMobile={helpBtnMobile}
            helpBtnDesktop={helpBtnDesktop}
          />
        </div>
      </li>
    );
  }
}

export default Step1;
