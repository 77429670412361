import React from 'react';
import { RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';

const InstructionalContent = (props) => {
  const { fields, rendering } = props;    
  return (
    <div>
      <RichText tag="h2" className="title" id={fields.id.value} field={fields.heading} />
      <nav className="level is-mobile">
        <div className="level-left">
          <Placeholder 
            rendering={rendering} 
            name="jss-ThumbnailList"
            renderEach={(component, index) => {
              return (
                <React.Fragment key={index}>
                  {component}
                </React.Fragment>
              )
            }}
          />
        </div>
      </nav>   
      <RichText field={fields.content} />
    </div>
  );
}  

export default InstructionalContent;