import React from 'react';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';

const Heading = (props) => (
  <div className="columns is-centered">
    <div className="column is-11-desktop">
      <div className="support__content is-flex-desktop">
        <Text tag="h2" className="title is-4 include-icon-before" field={props.fields.heading} />
        <Link className="sdw__button sdw__button--white is-external-link" field={props.fields.button} />
      </div>
    </div>
  </div>
);

export default Heading;
