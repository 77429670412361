import React from "react";
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Field, ErrorMessage } from 'formik';
import EditButton from './../EditButton/EditButton'
import isEmpty from './../../../../utilities/isEmptyObject'

class Step3 extends React.Component {
  constructor(props) {
    // fires before component is mounted
    super(props); // makes this refer to this component
    this.state = {}; // set state
  }

  render() {
    const { 
      values, 
      currentStep, 
      handleNext, 
      touched, 
      errors, 
      validateForm, 
      setTouched, 
      title, 
      labels,
      nextBtnLabel,
      handleEdit,
      isValid
    } = this.props;
    
    const isCurrentStep = currentStep < 3 ? "has-text-future" : "";
    return (
      <li className="step__panel">
        <h2 className={`${isCurrentStep} title is-3`}>
          <Text field={title} />
          {currentStep > 3 &&
            <EditButton handleEdit={handleEdit} validateForm={validateForm} currentStep={currentStep} thisStep={3} label={this.props.edit} />
          }  
        </h2>
        {currentStep === 3 &&
          <fieldset className="step__panel__inner" id={`fieldset__${currentStep}`}>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className={`field ${(errors.clinicName && touched.clinicName) ? 'has--error' : ''}`}>
                  <label htmlFor="clinicName" className="label">
                    <Text field={labels.formLabelClinicName} />             
                  </label>
                  <div className="control">
                    <Field
                      className="input"
                      id="clinicName"
                      name="clinicName"
                      type="text"
                      placeholder={labels.formLabelClinicName.value}
                      required
                    />                
                  </div>
                  <ErrorMessage name="clinicName" render={msg => <div className="error__message">{msg}</div>} />             
                </div>

                <div className={`field ${(errors.clinicianName && touched.clinicianName) ? 'has--error' : ''}`}>
                  <label htmlFor="clinicianName" className="label">
                    <Text field={labels.formLabelClinicianName} />   
                  </label>
                  <div className="control">
                    <Field
                      className="input"
                      id="clinicianName"
                      name="clinicianName"
                      type="text"
                      placeholder={labels.formLabelClinicianName.value}
                      required
                    />                
                  </div>
                  <ErrorMessage name="clinicianName" render={msg => <div className="error__message">{msg}</div>} />        
                </div>
              </div>
            </div>             

            <br />
            <button data-ecategory="SR clinic details" type="button" className={`sdw__button sdw__button--cochlear-yellow ${(isEmpty(errors) && isValid) ? '' : 'sdw__button--disabled'}`}
              disabled={!isEmpty(errors) || !isValid}
              onClick={() => validateForm().then(validation => {
                setTouched(validation)
                if (isEmpty(validation)) {
                  handleNext(validateForm)
                }
              })}
            >
              <Text field={nextBtnLabel} />
            </button>
          </fieldset>
        }
        {currentStep > 3 &&
          <div className="step__panel__inner">
            <p>{values.clinicName}</p>
            <p>{values.clinicianName}</p>
          </div>
        }
      </li>
    );
  }
}

export default Step3;
