import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import ArticleDate from './../custom/ArticleDate/ArticleDate';

import CategoryLink from './../custom/CategoryLink/CategoryLink';
import DeviceLink from './../custom/DeviceLink/DeviceLink';

import upgradeIcon from './../../assets/icons/upgrade_hero.png';
import config from './../../common/config';

const { country, lang } = config;

const ArticlesListing = (props) => {
  const { fields, params } = props;

  // strip out spaces, replace with hypens and transform to lowercase
  const devicesUrl = params?.device?.replace(/ /g, '-').toLowerCase();
  const categoryUrl = params?.category
    ?.replace(/ /g, '-')
    .toLowerCase();

  if (params.isHero) {
    return (
      <div className="articles__list__item articles__list__item--hero">
        <figure className="articles__list__item__image">
          <img src={upgradeIcon} alt="upgrade" className="" />
        </figure>
        <div className="articles__list__item__content">
          <div className="articles__list__item__title">
            <RichText
              tag="h3"
              className="title"
              field={fields.title}
            />
          </div>
          <div className="articles__list__item__details">
            <RichText tag="p" field={fields.content} />
            <Link
              data-ecategory="Articles Listing"
              to={`/${country}/${lang}${fields.button.value.href}`}
              className="sdw__button sdw__button--link"
            >
              {fields.button.value.text}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`articles__list__item ${
        params.isHiddenMobile ? 'is-hidden-mobile' : ''
      }`}
    >
      <div className="articles__list__item__title">
        <h3 className="title">
          <Link
            data-ecategory="Articles Listing"
            to={`/${country}/${lang}${fields.heading.value.href}`}
            dangerouslySetInnerHTML={{
              __html: fields.heading.value.text,
            }}
          />
        </h3>
      </div>
      <div className="articles__list__item__details">
        <ArticleDate
          label={fields.dateLabel.value}
          date={fields.date.value}
        />
        <span className="articles__list__item__details__group">
          <DeviceLink
            link={`/r/${devicesUrl}/`}
            device={params.device}
          />
          <CategoryLink
            link={`/r/${devicesUrl}/${categoryUrl}/`}
            category={params.category}
            class={categoryUrl}
          />
        </span>
      </div>
    </div>
  );
};

export default ArticlesListing;
