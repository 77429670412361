// get the user country code and return dcx country
export default function getCochlearCountry(countryCode) {
  let code = '';
  switch (countryCode) {
    case 'US':
    case 'PR':
      code = 'US';
      break;
    case 'GB':
    case 'UK':
    case 'IE':
      code = 'UK';
      break;
    case 'AU':
    case 'NZ':
      code = 'AU';
      break;
    default:
      code = countryCode;
  }
  return code.toLowerCase(); // it'll be appened to the cochlear homepage url
}
