import React from "react";
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Field, ErrorMessage } from 'formik'
import EditButton from './../EditButton/EditButton'
import isEmpty from './../../../../utilities/isEmptyObject'

class Step4 extends React.Component {

  render() {
    const { 
      values, 
      currentStep, 
      handleNext, 
      touched, 
      errors, 
      validateForm, 
      setTouched, 
      title, 
      labels,
      nextBtnLabel,
      selectLabel,
      handleEdit,
      isValid
    } = this.props;
    const isCurrentStep = currentStep < 4 ? "has-text-future" : "";
    return (
      <li className="step__panel">
        <h2 className={`${isCurrentStep} title is-3`}>
          <Text field={title} />
          {currentStep > 4 &&
            <EditButton handleEdit={handleEdit} validateForm={validateForm}  currentStep={currentStep} thisStep={4} label={this.props.edit} />
          }           
        </h2>
        {currentStep === 4 &&
          <fieldset className="step__panel__inner" id={`fieldset__${currentStep}`}>
            <div className={`field field--problem-topic ${(errors.problemTopic && touched.problemTopic) ? 'has--error' : ''}`}>
              <label htmlFor="problemTopic" className="label">
                <Text field={labels.formLabelProblemTopic} />
              </label>
              <div className="control">
                <div className="select">
                  <Field
                    component="select"
                    id="problemTopic"
                    name="problemTopic"
                    required
                  >
                    <option disabled value=''>{selectLabel.value}</option>
                    <option>{labels.formLabelProblemTopicOption1.value}</option>
                    <option>{labels.formLabelProblemTopicOption2.value}</option>
                    <option>{labels.formLabelProblemTopicOption3.value}</option>
                    <option>{labels.formLabelProblemTopicOption4.value}</option>
                  </Field>
                </div>
              </div>
              <ErrorMessage name="problemTopic" render={msg => <div className="error__message">{msg}</div>} />  
            </div>

            <div className={`field ${(errors.problemDescription && touched.problemDescription) ? 'has--error' : ''}`}>
              <label htmlFor="problemDescription" className="label">
                <Text field={labels.formLabelProblemDescription} />
              </label>
              <div className="control">
                <Field 
                  component="textarea"
                  className="textarea" 
                  id="problemDescription"
                  name="problemDescription"     
                  required          
                  placeholder={labels.formLabelProblemDescriptionPlaceholder.value}
                  rows="10" 
                />             
              </div>
              <ErrorMessage name="problemDescription" render={msg => <div className="error__message">{msg}</div>} />      
            </div>    

            <br />
            <button data-ecategory="SR issue details" type="button" className={`sdw__button sdw__button--cochlear-yellow ${(isEmpty(errors) && isValid) ? '' : 'sdw__button--disabled'}`}
              disabled={!isEmpty(errors) || !isValid}
              onClick={() => validateForm().then(validation => {
                setTouched(validation)
                if (isEmpty(validation)) {
                  handleNext(validateForm)
                }
              })}
            >
              <Text field={nextBtnLabel} />
            </button>
          </fieldset>
        }
        {currentStep > 4 &&
          <div className="step__panel__inner">
            <p>{values.problemTopic}</p>
            <p>{values.problemDescription}</p>
          </div>
        }
      </li>
    );
  }
}

export default Step4;
