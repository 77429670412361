import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import config from './../../../common/config'

const { country, lang } = config;
const CategoryLink = (props) => (
  <Link 
    data-ecategory="Article Group"
    to={`/${country}/${lang}${props.link}`}
    className={`include-icon-before article__category__link article__category__link--${props.class}`}
  >
    {props.category} 
  </Link>
);

// Specifies the default values for props:
CategoryLink.defaultProps = {
  link: '',
  category: '',
  class: '',
};

CategoryLink.propTypes = {
  link: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  class: PropTypes.string.isRequired,
};

export default CategoryLink
