import React, { useState } from 'react';

import MIAWChat from '../MIAWChat/MIAWChat';
import ChatSFHC from '../ChatSFHC/ChatSFHC';

// ChatSwitch used to handle chat components
// when an agent is online, show MIAWChat
// otherwise show ChatSFHC

const ChatSwitch = ({ action }) => {
  // This is the default settting
  const [showMIAW, setShowMIAW] = useState(true);

  // callback function
  // If miaw if not available, a callback function is called from MIAW chat
  // setting show MIAW to false and displaying chatbot instead
  const callbackMiawCheck = () => {
    setShowMIAW(false);
  };

  // When showMIAW is set to true, the component will first check if miaw is available
  // before actually showing the miaw chat
  return showMIAW ? (
    <MIAWChat action={action} callbackMiawCheck={callbackMiawCheck} />
  ) : (
    <ChatSFHC action={action} />
  );
};

export default ChatSwitch;
