import React from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { Link, Redirect } from 'react-router-dom';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
// import { withNamespaces } from 'react-i18next';
import AvailabilityStatus from './components/custom/AvailabilityStatus/AvailabilityStatus';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import FooterLinks from './components/custom/FooterLinks/FooterLinks';

import config from './common/config';
import { authenticationService } from './_services';

const { country, lang } = config;

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      route,
      chat,
      onlineSupport,
      serviceRequests,
      showSitemap,
    } = this.props;

    // text label strings
    const statusLabelOnlineSupport =
      onlineSupport === 'true' ? 'Available' : 'Offline';
    const statusLabelChat = chat === 'true' ? 'Online' : 'Offline';
    const statusLabelServiceRequests =
      serviceRequests === 'true' ? '' : 'Coming soon';

    function handleDisabled(e) {
      e.preventDefault();
    }

    // chat button trigger
    function handleChatClick(e) {
      e.preventDefault();

      // chatbot
      const findChatBtn = document.querySelectorAll(
        '.embeddedServiceHelpButton button.helpButtonEnabled',
      );

      if (window.embedded_svc && findChatBtn.length > 0)
        window.embedded_svc.liveAgentAPI.startChat();

      // miaw
      const findMiawButton = document.querySelectorAll(
        '.embedded-messaging',
      );

      if (
        window.embeddedservice_bootstrap &&
        findMiawButton.length > 0
      )
        window.embeddedservice_bootstrap.utilAPI.launchChat();
    }

    function showModal(e) {
      e.preventDefault();
      let modal = document.getElementsByClassName(
        'modal--cant-see-my-device',
      )[0];
      modal.classList.add('is-active');
      let html = document.documentElement;
      html.classList.add('is-clipped');
    }

    function handleServiceRequestLink(e) {
      // check if logged in
      const token = authenticationService.currentUserValue;
      const decoded = token && jwtDecode(token);

      const appAccess =
        decoded && decoded['https://www.cochlear.com/app'];
      const countryCode =
        decoded && decoded['https://www.cochlear.com/country_code'];
      const userType =
        decoded && decoded['https://www.cochlear.com/user_type'];
      const locale = decoded && decoded['locale'];
      // logged in user - check token exists and user has access
      if (token && appAccess && appAccess.includes('ds')) {
        return true;
      }

      // logged in user - NO DS CLAIM
      if (token && appAccess) {
        authenticationService.setReferrer('location', 'goBackUrl');
        return (
          <Redirect
            to={{
              pathname: `/${country}/${lang}/not-authorized`,
              state: {
                countryCode: countryCode,
                userType: userType,
                locale: locale,
              },
            }}
          />
        );
      }

      // not logged in
      // apply cookie for referrer as 'service-request' path
      // apply cookie for back/return button as current page url
      authenticationService.setReferrer('sr');
      authenticationService.setReferrer('location', 'goBackUrl');
      return true;
    }

    const disclaimerTitle = (
      <>
        <hr />
        <h3 className="title is-4">Disclaimer</h3>
      </>
    );

    return (
      <footer className="footer">
        <section
          className={`footer__sitemap ${
            showSitemap ? '' : 'is-hidden'
          }`}
        >
          <div className="container">
            {/* menu--columns */}
            <div className="columns is-centered">
              <div className="column is-11-desktop">
                <div className="footer__sitemap__header">
                  <h2 className="title is-2">Support sitemap</h2>
                  <Placeholder
                    name="jss-breadcrumb"
                    rendering={route}
                  />
                  <hr />
                </div>
                <Accordion
                  allowZeroExpanded={true}
                  className="columns is-multiline"
                >
                  <AccordionItem className="column is-one-quarter">
                    <div className="menu">
                      <AccordionItemHeading className="title is-5">
                        <AccordionItemButton>
                          Nucleus<sup>&reg;</sup>&nbsp;support
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul>
                          <li>
                            <Link
                              data-ecategory="Sitemap Links"
                              to={`/${country}/${lang}/r/nucleus-8/articles`}
                            >
                              All Nucleus 8 articles
                            </Link>
                          </li>
                          <li>
                            <Link
                              data-ecategory="Sitemap Links"
                              to={`/${country}/${lang}/r/nucleus-7/articles`}
                            >
                              All Nucleus 7 articles
                            </Link>
                          </li>
                          <li>
                            <Link
                              data-ecategory="Sitemap Links"
                              to={`/${country}/${lang}/r/nucleus-6/articles`}
                            >
                              All Nucleus 6 articles
                            </Link>
                          </li>
                        </ul>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                  <AccordionItem className="column is-one-quarter">
                    <div className="menu">
                      <AccordionItemHeading className="title is-5">
                        <AccordionItemButton>
                          Kanso<sup>&reg;</sup>&nbsp;support
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul>
                          <li>
                            <Link
                              data-ecategory="Sitemap Links"
                              to={`/${country}/${lang}/r/kanso-2/articles`}
                            >
                              All Kanso 2 articles
                            </Link>
                          </li>
                          <li>
                            <Link
                              data-ecategory="Sitemap Links"
                              to={`/${country}/${lang}/r/kanso/articles`}
                            >
                              All Kanso articles
                            </Link>
                          </li>
                        </ul>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                  <AccordionItem className="column is-one-quarter">
                    <div className="menu">
                      <AccordionItemHeading className="title is-5">
                        <AccordionItemButton>
                          Baha<sup>&reg;</sup>&nbsp;support
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul>
                          <li>
                            <Link
                              data-ecategory="Sitemap Links"
                              to={`/${country}/${lang}/r/baha-6-max/articles`}
                            >
                              All Baha 6 Max articles
                            </Link>
                          </li>
                          <li>
                            <Link
                              data-ecategory="Sitemap Links"
                              to={`/${country}/${lang}/r/baha-5/articles`}
                            >
                              All Baha 5 articles
                            </Link>
                          </li>
                        </ul>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                  <AccordionItem className="column is-one-quarter">
                    <div className="menu">
                      <AccordionItemHeading className="title is-5">
                        <AccordionItemButton>
                          Osia<sup>&reg;</sup>&nbsp;support
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul>
                          <li>
                            <Link
                              data-ecategory="Sitemap Links"
                              to={`/${country}/${lang}/r/osia-2/articles`}
                            >
                              All Osia 2 articles
                            </Link>
                          </li>
                        </ul>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                  <AccordionItem className="column">
                    <div className="menu show-availability">
                      <AccordionItemHeading className="title is-5">
                        <AccordionItemButton>
                          <span className="is-hidden-mobile">
                            Further
                          </span>{' '}
                          support options
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="columns colums--support-menu">
                          <div className="column is-one-quarter">
                            <ul>
                              <li>
                                <a
                                  className={`${
                                    onlineSupport === 'true'
                                      ? 'is-online'
                                      : 'is-offline'
                                  }`}
                                  href={`tel:+${config.countryDialingCode}-${config.phoneNumber}`}
                                  disabled={onlineSupport === 'false'}
                                  onClick={
                                    onlineSupport === 'false'
                                      ? handleDisabled
                                      : undefined
                                  }
                                  data-ecategory="Sitemap Links"
                                >
                                  Call us on{' '}
                                  <span className="phone">
                                    {config.phoneNumber}
                                  </span>
                                  <AvailabilityStatus
                                    label={
                                      statusLabelOnlineSupport || ''
                                    }
                                    showLabel={true}
                                    class={
                                      onlineSupport === 'true'
                                        ? 'is-online'
                                        : 'is-offline'
                                    }
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/"
                                  className={`${
                                    chat === 'true'
                                      ? 'is-online'
                                      : 'is-offline'
                                  }`}
                                  disabled={chat === 'false'}
                                  onClick={
                                    chat === 'false'
                                      ? handleDisabled
                                      : handleChatClick
                                  }
                                  data-ecategory="Sitemap Links"
                                >
                                  Chat online
                                  <AvailabilityStatus
                                    label={statusLabelChat || ''}
                                    showLabel={true}
                                    class={
                                      chat === 'true'
                                        ? 'is-online'
                                        : 'is-offline'
                                    }
                                  />
                                </a>
                              </li>
                              <li>
                                <Link
                                  to={`/${country}/${lang}/service-request`}
                                  className={`${
                                    serviceRequests === 'true'
                                      ? ''
                                      : 'is-coming-soon'
                                  }`}
                                  disabled={
                                    serviceRequests === 'false'
                                  }
                                  onClick={
                                    serviceRequests === 'false'
                                      ? handleDisabled
                                      : handleServiceRequestLink
                                  }
                                  data-ecategory="Sitemap Links"
                                >
                                  Submit your device for repair
                                  {serviceRequests === 'false' && (
                                    <div className="availability-status availability-status--is-coming-soon">
                                      {statusLabelServiceRequests}
                                    </div>
                                  )}
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="column is-1"></div>
                          <div className="column is-one-third">
                            <ul>
                              <li>
                                <a
                                  data-ecategory="Footer Links"
                                  href="https://www.cochlear.com/us/en/connect/contact-us/contact-us-recipient"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="external__link"
                                >
                                  Make an online inquiry
                                </a>
                              </li>
                              <li>
                                <a
                                  data-ecategory="Footer Links"
                                  href="https://www.cochlear.com/us/en/home/ongoing-care-and-support/device-support"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="external__link"
                                >
                                  Visit device support to get videos,
                                  user guides and more
                                </a>
                              </li>
                              <li>
                                <a
                                  data-ecategory="Footer Links"
                                  href="/"
                                  className="help__link include-icon-before"
                                  onClick={showModal}
                                >
                                  My sound processor is not here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                </Accordion>

                <div className="disclaimer">
                  <p>
                    For a full list of Cochlear's trademarks, please
                    visit our{' '}
                    <a
                      href="https://www.cochlear.com/us/en/corporate/terms-of-use/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="external__link"
                    >
                      Terms of Use
                    </a>{' '}
                    page.
                  </p>

                  {route &&
                    (route.itemId === 'home-page' ||
                      route.itemId ===
                        '5bf659b8-5ec4-51a1-8fd8-d000b2ba6051') && (
                      <>
                        {disclaimerTitle}
                        <p style={{ marginBottom: '.5em' }}>
                          1. Cochlear Limited. D1190805 Processor Size
                          Comparison. 2022, May.
                          <br />
                          2. Cochlear Limited. D1864200 SCAN 2 Design
                          Description. 2022, Apr.
                          <br />
                          3. Cochlear Limited. D1964109 CLTD5804
                          Clinical Investigation Report – Feb 2022.
                          <br />
                          4. Hunn N. Introducing Bluetooth
                          <sup>&reg;</sup> LE Audio [Internet]. [Cited
                          2022 Jan]. Available from:&nbsp;
                          <a
                            href="https://www.bluetooth.com/learn-about-bluetooth/recent-enhancements/le-audio/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="external__link"
                          >
                            https://www.bluetooth.com/learn-about-bluetooth/recent-enhancements/le-audio/
                          </a>
                          <br />^ Compared to previous generation
                          Nucleus 7 and Nucleus 6 Sound Processors
                        </p>
                      </>
                    )}
                  {route &&
                    (route.itemId ===
                      'articles-page-how-to-upgrade-to-the-nucleus-8-sound-processor' ||
                      route.itemId.toUpperCase() ===
                        'BD697294-778E-5343-A221-94398D717878') && (
                      <>
                        {disclaimerTitle}
                        <p style={{ marginBottom: '.5em' }}>
                          1. Cochlear Limited. D1190805, Processor
                          Size Comparison
                          <br />* Compared to Nucleus 6 and Nucleus 7
                          sound processors.
                        </p>
                      </>
                    )}
                  {route &&
                    (route.itemId ===
                      'kanso-2-articles-page-i-cannot-hear-any-sound-or-the-sound-comes-on-and-off' ||
                      route.itemId.toUpperCase() ===
                        '8ADA1B24-4515-56E9-8D6A-C47566114857' ||
                      route.itemId ===
                        'kanso-2-articles-page-the-sound-is-too-loud-or-uncomfortable' ||
                      route.itemId.toUpperCase() ===
                        'E8D04FE3-F746-57C8-A9CA-AB19C989C15A' ||
                      route.itemId ===
                        'kanso-2-articles-page-i-want-to-make-sure-my-sound-processor-is-receiving-sound' ||
                      route.itemId.toUpperCase() ===
                        'BAE0A375-3D7C-5776-8F3C-5C66B1960E71' ||
                      route.itemId ===
                        'kanso-2-articles-page-i-have-lost-my-sound-processor' ||
                      route.itemId.toUpperCase() ===
                        '8EFCD3BC-2DA2-5569-84DE-EDA2C8AC201E' ||
                      route.itemId ===
                        'kanso-2-articles-page-i-cant-hear-sound-when-i-use-a-wireless-accessory' ||
                      route.itemId.toUpperCase() ===
                        '1D59C57D-265C-58AB-9014-3DEA5EFB5738' ||
                      route.itemId ===
                        'kanso-2-articles-page-the-nucleus-smart-app-says-my-sound-processor-is-unavailable' ||
                      route.itemId.toUpperCase() ===
                        'D240D929-AF47-5632-B59E-E8DFC9750050') && (
                      <>
                        {disclaimerTitle}
                        <p style={{ marginBottom: '.5em' }}>
                          The Cochlear Nucleus Smart App is available
                          on App Store and Google Play. For complete
                          smartphone compatibility information visit{' '}
                          <a
                            href="https://www.cochlear.com/compatibility"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="external__link"
                          >
                            www.cochlear.com/compatibility.
                          </a>
                        </p>
                      </>
                    )}
                  {route &&
                    (route.itemId ===
                      'osia-2-articles-page-i-have-lost-my-sound-processor' ||
                      route.itemId.toUpperCase() ===
                        'CEE92626-DB76-5676-B47D-8A1D07CFD792') && (
                      <>
                        {disclaimerTitle}
                        <p style={{ marginBottom: '.5em' }}>
                          Apple, iPhone, iPad and iPod touch are
                          trademarks of Apple, Inc., registered in the
                          U.S. and other countries. Android is a
                          trademark of Google LLC.
                        </p>
                        <p style={{ marginBottom: '.5em' }}>
                          The Cochlear Osia<sup>&reg;</sup> 2 Sound
                          Processor is compatible with iPhone, iPad
                          and iPod touch. For compatibility
                          information visit{' '}
                          <a
                            href="https://www.cochlear.com/compatibility"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="external__link"
                          >
                            www.cochlear.com/compatibility.
                          </a>
                        </p>
                      </>
                    )}
                  {route &&
                    (route.itemId ===
                      'nucleus-8-articles-page-my-sound-processor-wont-pair-with-my-phone' ||
                      route.itemId.toUpperCase() ===
                        '98A7D5F4-0B36-5287-9A5F-959119C7B1EC' ||
                      route.itemId ===
                        'nucleus-8-articles-page-my-sound-processor-wont-stream-audio-from-my-phone' ||
                      route.itemId.toUpperCase() ===
                        '177F40C5-A2DA-59BD-B823-17B97DE4B6A4') && (
                      <>
                        {disclaimerTitle}
                        <p style={{ marginBottom: '.5em' }}>
                          The Bluetooth<sup>&reg;</sup> word mark and
                          logos are registered trademarks owned by
                          Bluetooth SIG, Inc. and any use of such
                          marks by Cochlear Limited is under license.
                          Other trademarks and trade names are those
                          of their respective owners.
                        </p>
                      </>
                    )}
                  {route &&
                    (route.itemId ===
                      'nucleus-8-articles-page-my-processor-wont-connect-back-to-my-phone-after-i-go-out-of-range' ||
                      route.itemId.toUpperCase() ===
                        'C8545990-1BCE-5342-883A-4BC60F21A9F1') && (
                      <>
                        {disclaimerTitle}
                        <p style={{ marginBottom: '.5em' }}>
                          Please seek advice from your health
                          professional about treatments for hearing
                          loss. Outcomes may vary, and your health
                          professional will advise you about the
                          factors which could affect your outcome.
                          Always read the instructions for use. Not
                          all products are available in all countries.
                          Please contact your local Cochlear
                          representative for product information.
                        </p>
                      </>
                    )}

                  {route &&
                    route.itemId ===
                      'osia-2-articles-page-im-not-sure-how-to-care-for-my-sound-processor' && (
                      <>
                        {disclaimerTitle}
                        <p style={{ marginBottom: '.5em' }}>
                          The Cochlear Osia System is protected
                          against water to level IP52 of the
                          International Standard IEC60529 and IP57
                          with the battery compartment removed. Refer
                          to the relevant{' '}
                          <a
                            href="https://www.cochlear.com/us/en/campaign/ifu/osia-system/recipients"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="external__link"
                          >
                            User Guide
                          </a>{' '}
                          for more information.
                        </p>
                      </>
                    )}

                  {route &&
                    (route.itemId ===
                      'nucleus-7-articles-page-im-having-trouble-connecting-my-resound-hearing-aid-to-the-nucleus-smart-app-on-my-android-device' ||
                      route.itemId ===
                        'nucleus-7-articles-page-im-having-trouble-connecting-my-resound-hearing-aid-to-the-nucleus-smart-app-on-my-apple-device' ||
                      route.itemId ===
                        'nucleus-7-articles-page-im-having-trouble-navigating-bimodal-control-in-the-nucleus-smart-app' ||
                      route.itemId ===
                        'nucleus-8-articles-page-im-having-trouble-connecting-my-resound-hearing-aid-to-the-nucleus-smart-app-on-my-android-device' ||
                      route.itemId ===
                        'nucleus-8-articles-page-im-having-trouble-connecting-my-resound-hearing-aid-to-the-nucleus-smart-app-on-my-apple-device' ||
                      route.itemId ===
                        'nucleus-8-articles-page-im-having-trouble-navigating-bimodal-control-in-the-nucleus-smart-app' ||
                      route.itemId ===
                        'kanso-2-articles-page-im-having-trouble-connecting-my-resound-hearing-aid-to-the-nucleus-smart-app-on-my-android-device' ||
                      route.itemId ===
                        'kanso-2-articles-page-im-having-trouble-connecting-my-resound-hearing-aid-to-the-nucleus-smart-app-on-my-apple-device' ||
                      route.itemId ===
                        'kanso-2-articles-page-im-having-trouble-navigating-bimodal-control-in-the-nucleus-smart-app') && (
                      <>
                        {disclaimerTitle}
                        <p style={{ marginBottom: '.5em' }}>
                          *Bimodal control in the Nucleus Smart App is
                          only available in the United States.
                        </p>
                        <p style={{ marginBottom: '.5em' }}>
                          Cochlear and ReSound collaborate in the
                          Smart Hearing Alliance to develop advanced
                          and innovative hearing solutions for you.
                        </p>
                        <p style={{ marginBottom: '.5em' }}>
                          ReSound is part of the GN group. GN makes
                          life sound better through intelligent audio
                          solutions.
                        </p>
                        <p style={{ marginBottom: '.5em' }}>
                          Please seek advice from your health
                          professional about treatments for hearing
                          loss. Outcomes may vary, and your health
                          professional will advise you about the
                          factors which could affect your outcome.
                          Always follow the directions for use. Not
                          all products are available in all countries.
                        </p>
                        <p style={{ marginBottom: '.5em' }}>
                          For compatibility information and devices
                          visit cochlear.com/compatibility and
                          resound.com/compatibility
                        </p>
                        <p style={{ marginBottom: '.5em' }}>
                          ReSound is a trademark of GN Hearing A/S.
                        </p>
                        <p style={{ marginBottom: '.5em' }}>
                          Apple and the Apple logo are trademarks of
                          Apple Inc., registered in the U.S. and other
                          countries. App Store is a service mark of
                          Apple Inc., registered in the U.S. and other
                          countries.
                        </p>
                        <p style={{ marginBottom: '.5em' }}>
                          Android, Google Play, the Google Play logo
                          and Pixel are trademarks of Google LLC.
                        </p>
                        <p style={{ marginBottom: '.5em' }}>
                          The Bluetooth word mark and logos are
                          registered trademarks owned by Bluetooth
                          SIG, Inc.
                        </p>
                        <p style={{ marginBottom: '.5em' }}>
                          ©Cochlear Limited 2023. All rights reserved.
                          Cochlear, Hear now. And always, Nucleus,
                          Kanso, Baha, Osia, the elliptical logo, and
                          marks bearing an ® or ™ symbol, are either
                          trademarks or registered trademarks of the
                          Cochlear group of companies (unless
                          otherwise noted).
                        </p>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterLinks />
      </footer>
    );
  }
}

// Specifies the default values for props:
Footer.defaultProps = {
  showSitemap: true,
};

Footer.propTypes = {
  showSitemap: PropTypes.bool,
};

// Footer = withNamespaces()(Footer);
export default Footer;
