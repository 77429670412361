// get the ref app and return app value and homepage
export default function getSupportedCountry(countryCode) {
  let code = '';
  switch (countryCode) {
    case 'US':
    case 'PR':
      code = 'US';
      break;
    default:
      code = '';
  }
  return code;
}
