import React from 'react';
import { Link } from 'react-router-dom';
import config from './../../common/config'

const { country, lang } = config;

const BreadcrumbItem = (props) => {
  const { fields = {}, params = {} } = props; 
  const current = params.current === 'true';
  if (!fields.internalLink) { 
    return null
  }
  if (current) {
    return <li className="is-active"><Link data-ecategory="Breadcrumb" aria-current="page" to={`/${country}/${lang}${fields.internalLink.value.href}`}>{fields.internalLink.value.text}</Link></li>;
  }
  return <li><Link data-ecategory="Breadcrumb" to={`/${country}/${lang}${fields.internalLink.value.href}`}>{fields.internalLink.value.text}</Link></li>;
};

export default BreadcrumbItem;
