import React from 'react';
import PropTypes from 'prop-types';

const AvailabilityStatus = (props) => (
  <div className={`availability-status availability-status--${props.class} ${props.showLabel && 'availability-status--has-label'}`}>
    {
      props.showLabel && props.label
    }
  </div>
);

// Specifies the default values for props:
AvailabilityStatus.defaultProps = {
  class: '',
  label: '',
  showLabel: false,
};

AvailabilityStatus.propTypes = {
  class: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
};

export default AvailabilityStatus