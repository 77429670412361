import React, { Component } from 'react';
import PropTypes from 'prop-types'; 
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
// const ContentBlock = ({ fields }) => (
//   <React.Fragment>
//     <Text tag="h2" className="display-4" field={fields.heading} />

//     <RichText className="contentDescription" field={fields.content} />
//   </React.Fragment>
// );

class ContentBlock extends Component {
  constructor(props) {
    // fires before component is mounted
    super(props); // makes this refer to this component
    this.state = {}; // set state
  }
  render() {
    const { fields } = this.props;
    return (
      <React.Fragment>
        <Text tag="h2" className="display-4" field={fields.heading} />
        <RichText className="contentDescription" field={fields.content} />
      </React.Fragment>
    )
  }
}

ContentBlock.propTypes = {
  fields: PropTypes.shape({
    /** "heading" is a string. */
    heading: PropTypes.shape({
      value: PropTypes.string
    }),
    /** "content" is a string. */
    content: PropTypes.shape({
      value: PropTypes.string
    })
  })
};

export default ContentBlock;
