import React from 'react';
import PropTypes from 'prop-types';

import USflag from './../../../assets/flags/us.png';
import UKflag from './../../../assets/flags/uk.png';
import AUflag from './../../../assets/flags/au.png';
import CAflag from './../../../assets/flags/ca.png';
import NOflag from './../../../assets/flags/no.png';
import DEflag from './../../../assets/flags/de.png';

function formatFlag(country) {
  let flag = null;
  switch (country) {
    case 'us':
      flag = USflag;
      break;
    case 'uk':
      flag = UKflag;
      break;
    case 'au':
      flag = AUflag;
      break;
    case 'de':
      flag = DEflag;
      break;
    case 'ca':
      flag = CAflag;
      break;
    case 'no':
      flag = NOflag;
      break;
    default:
      flag = USflag;
  }

  return <img src={flag} alt={country} />;
}

function formatLanguage(language) {
  let displayLang = null;
  switch (language) {
    case 'en':
      displayLang = 'Eng';
      break;
    default:
      displayLang = 'Eng';
  }

  return displayLang;
}

function CountryLanguage({ country, language }) {
  return (
    <div className="country-language">
      <div className="country-language__flag">
        {formatFlag(country)}
      </div>
      <div className="country-language__language-label">
        {formatLanguage(language)}
      </div>
    </div>
  );
}

export default CountryLanguage;

CountryLanguage.propTypes = {
  /**
    Used to display correct flag
  */
  country: PropTypes.string.isRequired,
  /**
    used to display correct language label
  */
  language: PropTypes.string.isRequired,
};
