import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';

const EditButton = (props) => {
  
  const {
    currentStep,
    thisStep,
    label,
    handleEdit,
    validateForm
  } = props;

  function handleEditOnClick(step) {
    handleEdit(step, validateForm);
  };

  if (currentStep > thisStep-1) {
    return (
      <button
        className="button is-text edit__button include-icon-after"
        onClick={() => handleEditOnClick(thisStep)}
      >
        <Text field={label} />
      </button>
    );
  }
  return null;

}

// // Specifies the default values for props:
EditButton.defaultProps = {
  currentStep: 0,
  thisStep: 0,
  label: {value: 'Edit'},
  handleEdit: () => {}
};

EditButton.propTypes = {
  currentStep: PropTypes.number.isRequired,
  thisStep: PropTypes.number.isRequired,
  label: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default EditButton