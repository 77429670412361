import React from 'react';
import { Link } from 'react-router-dom';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import config from './../../common/config'

const BreadcrumbSection = (props) => {
  const { rendering } = props; 
  const { country, lang } = config;
  return (
    <React.Fragment>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link data-ecategory="Breadcrumb" to={`/${country}/${lang}/home`}>Support home</Link></li>
          <Placeholder 
            rendering={rendering} 
            name="jss-Breadcrumbs" 
            renderEach={(component, index) => (
              <React.Fragment key={index}>
                {component}
              </React.Fragment>
            )}
          />
        </ul>
      </nav>
    </React.Fragment>
  )
};

export default BreadcrumbSection;