import React from 'react';
import { Link, Placeholder, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
// import './LayoutSection.scss';

const MyPlaceholder = (props) => {
  return (
    <Placeholder 
      rendering={props.rendering} 
      name="jss-LayoutSection" 
      renderEach={(component, index) => {
        // use the tag prop if it's been assigned
        const Tag = props.tag ? props.tag : React.Fragment;
        const condition = props.className && props.tag;

        // chat, serviceRequests, onlineSupport value passed from global sitearea to the main placholder 
        component.props.params.chat = props.chat;
        component.props.params.serviceRequests = props.serviceRequests;
        component.props.params.onlineSupport = props.onlineSupport;
        component.props.params.currentUser = props.currentUser;
        component.props.params.userId = props.userId;
        // category cards require a reference to the device
        component.props.params.forDevice = props.forDevice ? props.forDevice : null;

        return (
          <Tag
            key={index}
            { ...( condition && { className: props.className } ) }    // can only add `class` if it is an element, cant add it on React.Fragment
          >
            {component}
          </Tag>
        )
      }}
    />
  )
}

const LayoutSection = (props) => {
  
  function showModal(e, type) {
    e.preventDefault();
    let modal = document.getElementsByClassName("modal--"+type)[0];
    modal.classList.add("is-active");
    let html = document.documentElement;
    html.classList.add("is-clipped");
  }  

  return (
    <section className={`section ${props.fields.wrapperClass.value} ${props.fields.hide ? props.fields.hide.value : ''}`}>
      <div className="container">
        {(function() {
          switch(props.fields.type.value) {
            case 'card':
              return (
                <React.Fragment>
                  <Text tag="h2" className="title is-2 has-text-centered is-spaced" field={props.fields.heading} />
                  <RichText tag="p" className="subtitle has-text-centered" field={props.fields.description} />
                  <div className="columns is-centered card-columns">
                    <MyPlaceholder {...props} tag="div" className="column" />
                  </div>
                </React.Fragment>
              )
            case 'category':
              return (
                <React.Fragment>
                  <Text tag="h2" className="title is-2 has-text-centered" field={props.fields.heading} />
                  <div className="columns is-desktop is-centered">
                    <MyPlaceholder {...props} tag="div" className="column" forDevice={props.params.deviceCategory} />
                  </div>
                  {
                    props.fields.customButton &&
                    <div className="has-text-centered">
                      <Link data-ecategory={props.fields.customButton.value.ecategory} onClick={((e) => showModal(e, props.params.type))} field={props.fields.customButton}><span>{props.fields.customButton.value.text}</span></Link>
                    </div>
                  }
                </React.Fragment>
              )              
            case 'range':
              return (
                <React.Fragment>
                  <Text tag="h2" className="title is-3" field={props.fields.heading} />
                  <div className="columns is-desktop">
                    <MyPlaceholder {...props} />
                  </div>
                  {
                    props.fields.customButton &&
                    <div className="has-text-centered">
                      <Link data-ecategory={props.fields.customButton.value.ecategory} onClick={((e) => showModal(e, props.params.type))} field={props.fields.customButton}><span>{props.fields.customButton.value.text}</span></Link>
                    </div>
                  }
                </React.Fragment>
              )        
            case 'list':
              return (
                <React.Fragment>
                  <Text tag="h2" className="title is-3" field={props.fields.heading} />
                  <ul className="menu-list">
                    <MyPlaceholder {...props} />
                  </ul>
                </React.Fragment>
              )                                 
            default:
              return (
                <MyPlaceholder {...props} />
              )
          }
        })()}
      </div>
    </section>
  );
}

export default LayoutSection;