import React from 'react';
import PropTypes from 'prop-types';

function showModal(e, type) {
  e.preventDefault();
  let modal = document.getElementsByClassName("modal--"+type)[0];
  modal.classList.add("is-active");
  let html = document.documentElement;
  html.classList.add("is-clipped");
}  

const ArticleFeedback = (props) => (
  <nav className="level article__feedback">
    <div className="level-left">
      <div className="level-item">
        <p className="title is-5 is-marginless">{props.title}</p>           
      </div>
    </div>
    <div className="level-right">
      <p className="level-item"><button data-ecategory="Article Feedback" data-elabel="Positive" className="button is-success is-rounded include-icon-before" onClick={((e) => showModal(e, 'thumbs-up'))}>{props.labelPositive}</button></p>
      <p className="level-item"><button data-ecategory="Article Feedback" data-elabel="Negative" className="button is-danger is-rounded include-icon-before" onClick={((e) => showModal(e, 'thumbs-down'))}>{props.labelNegative}</button></p>
    </div>
  </nav>
);

// Specifies the default values for props:
ArticleFeedback.defaultProps = {
  title: '',
  labelPositive: '',
  labelNegative: '',
};

ArticleFeedback.propTypes = {
  title: PropTypes.string.isRequired,
  labelPositive: PropTypes.string.isRequired,
  labelNegative: PropTypes.string.isRequired,
};


export default ArticleFeedback