import React from "react";
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Field, ErrorMessage } from 'formik'

import isEmpty from './../../../../utilities/isEmptyObject'
import stateList from './../../../../utilities/stateList'

class Step5 extends React.Component {

  render() {
    const { 
      currentStep, 
      errors, 
      isSubmitting, 
      labels, 
      nextBtnLabel,
      optionalLabel,
      selectLabel,
      termsAndConditionsList,
      title, 
      titleTermsAndConditions,
      touched, 
      isValid,
    } = this.props;
    const isCurrentStep = currentStep < 5 ? "has-text-future" : "";
    return (
      <li className="step__panel">
        <Text tag="h2" className={`${isCurrentStep} title is-3`} field={title} />
        {currentStep === 5 && (
          <fieldset className="step__panel__inner" id={`fieldset__${currentStep}`}>
            
            <div className={`field ${(errors.addressLine1 && touched.addressLine1) ? 'has--error' : ''}`}>
              <label htmlFor="addressLine1" className="label">
                <Text field={labels.formLabelAddressLine1} />
              </label>
              <div className="control">
                <Field
                  className="input"
                  id="addressLine1"
                  name="addressLine1"
                  placeholder={labels.formLabelAddressLine1.value}
                  required
                  type="text"
                />        
              </div>
              <ErrorMessage name="addressLine1" render={msg => <div className="error__message">{msg}</div>} />             
            </div>

            <div className={`field ${(errors.addressLine2 && touched.addressLine2) ? 'has--error' : ''}`}>
              <label htmlFor="addressLine2" className="label">
                <Text field={labels.formLabelAddressLine2} /> <Text tag="p" className="help" field={optionalLabel} />
              </label>
              <div className="control">
                <Field
                  className="input"
                  id="addressLine2"
                  name="addressLine2"
                  placeholder={labels.formLabelAddressLine2.value}
                  type="text"
                />          
              </div>
              <ErrorMessage name="addressLine2" render={msg => <div className="error__message">{msg}</div>} />           
            </div>        

            <div className={`field ${(errors.addressLine3 && touched.addressLine3) ? 'has--error' : ''}`}>
              <label htmlFor="addressLine3" className="label">
                <Text field={labels.formLabelAddressLine3} /> <Text tag="p" className="help" field={optionalLabel} />
              </label>
              <div className="control">
                <Field
                  className="input"
                  id="addressLine3"
                  name="addressLine3"
                  type="text"
                  placeholder={labels.formLabelAddressLine3.value}
                />              
              </div>
              <ErrorMessage name="addressLine3" render={msg => <div className="error__message">{msg}</div>} />    
            </div>          

            <div className="field is-horizontal">
              <div className="field-body">

                <div className={`field ${(errors.city && touched.city) ? 'has--error' : ''}`}>
                  <label htmlFor="city" className="label">
                    <Text field={labels.formLabelCity} />
                  </label>
                  <div className="control">
                    <Field
                      className="input"
                      id="city"
                      name="city"
                      placeholder={labels.formLabelCity.value}
                      required
                      type="text"
                    />                     
                  </div>
                  <ErrorMessage name="city" render={msg => <div className="error__message">{msg}</div>} />            
                </div>         

                <div className={`field ${(errors.state && touched.state) ? 'has--error' : ''}`}>
                  <label htmlFor="state" className="label">
                    <Text field={labels.formLabelState} /> 
                  </label>
                  <div className="control">
                    <div className="select" style={{width: '100%'}}>
                      <Field
                        component="select"
                        id="state"
                        name="state"
                        required
                      >
                        <option disabled value=''>{selectLabel.value}</option>
                        {
                          stateList.map(state => {
                            return (
                              <option key={state.value} value={state.value}>{state.name}</option>
                            )
                          })
                        }
                      </Field>
                    </div>
                  </div>
                  <ErrorMessage name="state" render={msg => <div className="error__message">{msg}</div>} />       
                </div>

              </div>
            </div>         

            <div className={`field ${(errors.zipCode && touched.zipCode) ? 'has--error' : ''}`}>
              <label htmlFor="zipCode" className="label">
                <Text field={labels.formLabelZipCode} /> 
              </label>
              <div className="control" style={{width: '130px'}}>
                <Field
                  className="input"
                  id="zipCode"
                  name="zipCode"
                  type="text"
                  placeholder={labels.formLabelZipCode.value}
                  required
                />          
              </div>
              <ErrorMessage name="zipCode" render={msg => <div className="error__message">{msg}</div>} />       
            </div>       

            <div className="terms">
              <Text tag="h4" className="title is-4 terms__title" field={titleTermsAndConditions} /> 
              <RichText tag="ul" className="terms__list" field={termsAndConditionsList} />
            </div>

            <div className={`field field--checkbox ${(errors.terms && touched.terms) ? 'has--error' : ''}`}>
              <div className="control control--checkbox">
                <label className="checkbox">
                  <Field id="terms" name="terms" component="input" type="checkbox" required /> <RichText field={labels.formLabelTermsAndConditions} /> 
                  <div className="control__indicator"></div>
                </label>
              </div>
              <ErrorMessage name="terms" render={msg => <div className="error__message">{msg}</div>} /> 
            </div>       
            <br /> 
            <button data-ecategory="SR shipping details" type="submit" id="submitBtn" disabled={isSubmitting || (!isEmpty(errors) || !isValid)} className={`sdw__button sdw__button--purple ${(isEmpty(errors) && isValid) ? '' : 'sdw__button--disabled'}`}>
              {nextBtnLabel.value}
              {/* is submitting spinner  */}
              <div className="lds-ring"><div /><div /><div /><div /></div>
            </button>

          </fieldset>
        )}
      </li>
    );
  }
}

export default Step5;