// Convert a text string to html richtext to include registered trademark for the device name
export default function addRegToDevice(device, bool) {
  if (bool === 'false') return device;
  switch (device) {
    case 'Nucleus 8':
      return 'Nucleus<sup>&reg;</sup>&nbsp;8';
    case 'Nucleus 7':
      return 'Nucleus<sup>&reg;</sup>&nbsp;7';
    case 'Nucleus 6':
      return 'Nucleus<sup>&reg;</sup>&nbsp;6';
    case 'Nucleus 5':
      return 'Nucleus<sup>&reg;</sup>&nbsp;5';
    case 'Kanso 2':
      return 'Kanso<sup>&reg;</sup>&nbsp;2';
    case 'Baha 6 Max':
      return 'Baha<sup>&reg;</sup>&nbsp;6 Max';
    case 'Baha 5':
      return 'Baha<sup>&reg;</sup>&nbsp;5';
    case 'Baha 5 Power':
      return 'Baha<sup>&reg;</sup>&nbsp;5 Power';
    case 'Baha 5 SuperPower':
      return 'Baha<sup>&reg;</sup>&nbsp;5 SuperPower';
    case 'Baha 4':
      return 'Baha<sup>&reg;</sup>&nbsp;4';
    case 'Osia 2':
      return 'Osia<sup>&reg;</sup>&nbsp;2';
    case 'Nucleus':
    case 'Baha':
    case 'Kanso':
    case 'Freedom':
    case 'Osia':
      return `${device}<sup>&reg;</sup>`;

    case 'test case':
      return `${device}<sup>&reg;</sup>`;

    default:
      return device;
  }
}
