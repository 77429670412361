import React from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';

const LinkList = (props) => (
  <li>
    <Link field={props.fields.internalLink} />
  </li>
);

export default LinkList;
