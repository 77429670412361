import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import {
  authenticationService,
  environment,
} from './../../../_services';
import config from './../../../common/config';
import getMinutes from './../../../utilities/getMinutes';
import getSupportedCountry from './../../../utilities/getSupportedCountry';

const {
  country,
  lang,
  cookieDomain,
  authenticate,
  storeLogoutToken,
  cochlearMCR,
  drxMain,
} = config;
const domainValue = cookieDomain[environment];
const baseURL = config.baseURL[environment];
const { contents } = authenticate[environment];

const authToken = storeLogoutToken[environment];
const COCHLEAR_MCR = cochlearMCR[environment];
const DRX_MAIN = drxMain[environment];
const authorizeUser = config.authorizeUser[environment];
const storeLogout = config.storeLogout[environment];

function storeLogoutCountryCode(countryCode) {
  let code = '';
  switch (countryCode) {
    case 'PR':
      code = 'US';
      break;
    case 'NZ':
      code = 'AU';
      break;
    case 'GB':
    case 'IE':
      code = 'UK';
      break;
    default:
      code = countryCode;
  }
  return code;
}

class ProfileDropdown extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      logout: false,
      firstName: '',
      lastName: '',
      name: '',
      profileIsLoading: false,
      profileLoaded: false,
      profileData: {},
      profileDataError: null,
      supportedCountry: true,
      userType: '',
      countryCode: '',
      locale: '',
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.toggleClassDropdownNav =
      this.toggleClassDropdownNav.bind(this);
    this.onSetResult = this.onSetResult.bind(this);
    this.closePopOver = this.closePopOver.bind(this);
    this.toggleFeatures = this.toggleFeatures.bind(this);
  }

  /**
   *
   * Prop is set inside Layout.js and passed down via;
   * UniversalHeader - For Desktop
   * Navigation - For Mobile
   */
  toggleClassDropdownNav(e) {
    e && e.preventDefault();
    if (this.props.onChildClick) {
      this.props.onChildClick();
    }
  }

  toggleFeatures() {
    // call parent function to update prop
    this.props.callbackWhatsnew();
  }

  closePopOver() {
    const { version } = this.state.profileData.whatsnew;
    // save version to cookie
    Cookies.set('whatsnew', version, {
      path: '/',
      domain: domainValue,
      secure: true,
    });

    // this.setState({
    //   showPopOver: false,
    // });
    this.props.callbackShowPopOver(false);
  }

  handleLogout(e) {
    e.preventDefault();
    this.setState({
      logout: true,
    });

    const isSecure = this.props.privatePath;
    let thisPath = this.props.location.pathname;

    // logoutURL param indicates where to return the user after logout from SF
    // On a secure page: Redirect to support.cochlear.com/us/en/home after logout
    // On a unsecure page: Log out user and then redirect them to page they original intended to view (ie, current page)
    const logoutURL = isSecure
      ? baseURL + country + '/' + lang + '/home'
      : baseURL + thisPath.replace(/^\/+/, ''); // remove leading slash from this path

    // get the token and check if there is an app claim for store
    const token = authenticationService.currentUserValue;

    if (token) {
      const decoded = jwtDecode(token);

      const app = decoded['https://www.cochlear.com/app'];
      const check = app.includes('store');

      // remove http protocol from origin
      const origin = logoutURL.replace(/^https?:\/\//i, '');

      const chainLogout = encodeURIComponent(
        `${authorizeUser}/authorize?request=logout`,
      );

      const countryCode =
        decoded['https://www.cochlear.com/country_code'];
      const locale = decoded.locale;
      const languageCode = locale.substring(0, 2).toLowerCase();

      const code = storeLogoutCountryCode(countryCode);
      const storeLogoutEndPath = 'shop/storeLogout';

      // depending on env we might not have a store logout eg DEV
      if (check && storeLogout) {
        // store logout check

        // configure store logout to do a preflight check on UAT & PRD only
        // this check will confirm the env is up before redirecting the user there
        // if it is not available, skip the store logout redirection

        const storeLogoutCompiledPath = `${storeLogout}${
          environment !== 'SIT' ? code.toLowerCase() : code
        }/${languageCode}/${storeLogoutEndPath}`;

        let tokenConfig = authToken && {
          headers: {
            Authorization: `Basic ${authToken}`,
          },
        };

        // console.log(
        //   "calling store logout endpoint to check it's available",
        // );
        // Make a request for a user with a given ID
        axios
          .options(storeLogoutCompiledPath, tokenConfig)
          .then((response) => {
            // handle success

            // Store the post-logout redirect URL in a cookie to be consumed by the auth-application to handle the redirect
            // back to the application
            Cookies.set('referrerUrl', logoutURL, {
              domain: cookieDomain,
              secure: true,
            });

            // token revoke and SFlogout will take place on the authorizer
            window.location.replace(
              `${storeLogoutCompiledPath}?chainLogout=${chainLogout}&origin=${origin}`,
            );
            return false;
          })
          .catch((error) => {
            // handle error
            // console.log('error, unavailable: ', error);
            this.logoutUser(isSecure, logoutURL);
          });
        return false;
      }
      this.logoutUser(isSecure, logoutURL);
      return false;
    } // End if token

    // console.log('okay, tried everything else, just log the user out');
    // catch all log out

    // logout user without token
    // window.location.replace(
    //   `${CIM_RecipientLogout}?logoutURL=${logoutURL}`,
    // );
    window.location.replace(`${logoutURL}`);
  }

  // logout user with token
  // -----------------------
  // revoke token and logout - user has a token but no store claim so log them out after revoking their token
  // or the store endpoint was down
  logoutUser = (isPrivatePath, currentPage) => {
    // console.log('logging out user, but first revoking token');

    // Ignore the currentPage - it will be set in the auth service logout function
    // if (currentPage) {
    //   // Store the post-logout redirect URL in a cookie to be consumed by the auth-application to handle the redirect
    //   // back to the application
    //   Cookies.set('referrerUrl', currentPage, {
    //     domain: domainValue,
    //     secure: true,
    //   })
    // }
    authenticationService.logout(isPrivatePath);
    // .then(response => {
    //   // handle success
    //   console.log('response, successfully revoked: ', response);
    // })
    // .catch(error => {
    //   // handle error
    //   console.log('error, unsuccessfully revoked: ', error);
    // })
    // .then(() => {
    //     window.location.replace(
    //         `${currentPage}`,
    //     );
    //     // window.location.replace(
    //     // `${CIM_RecipientLogout}?logoutURL=${currentPage}`,
    //   // );
    // });
  };

  updateUserToken = (token) => {
    // update service
    authenticationService.updateCurrentUser(token);

    // update cookie
    Cookies.set('currentUser', token, {
      domain: domainValue,
      secure: true,
      expires: getMinutes(120),
    });
  };

  onSetResult(result) {
    const { uhmenu } = result.data.data;
    this.setState(
      {
        profileLoaded: true,
        profileIsLoading: false,
        profileData: uhmenu,
      },
      () => this.handlePopOver(),
    );
  }

  onErrorResult(error) {
    const token = authenticationService.currentUserValue;
    // Make a request for a user with a given ID
    const decoded = token && jwtDecode(token);

    this.setState({
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      profileIsLoading: false,
      profileDataError: error,
    });
  }

  handlePopOver() {
    if (this.state.profileLoaded) {
      // pass back whats new content
      this.props.callbackWhatsnewContent(
        this.state.profileData.whatsnew,
      );
      const { toggle, version } = this.state.profileData.whatsnew;
      const popOver = Cookies.get('whatsnew');

      // cookie doesnt exist, lets show the popover
      if (!popOver) {
        // return this.setState({
        //   showPopOver: toggle === 'on' && true,
        // });
        return (
          toggle === 'on' && this.props.callbackShowPopOver(true)
        );
      }
      if (version > popOver) {
        // return this.setState({
        //   showPopOver: toggle === 'on' && true,
        // });
        return (
          toggle === 'on' && this.props.callbackShowPopOver(true)
        );
      }
    }
    return null;
  }

  // parent component handles whether to show load the component or not depending on whether the user has app access
  componentDidMount() {
    this._isMounted = true;
    this.setState({ profileIsLoading: true });

    const token = authenticationService.currentUserValue;
    // Make a request for a user with a given ID
    const decoded = token && jwtDecode(token);

    if (decoded) {
      // handles country not supported. Other variants are covered elsewhere (AppRoot and Card)
      const name = decoded.name;
      const firstName = decoded.given_name;
      const lastName = decoded.family_name;
      const userType = decoded['https://www.cochlear.com/user_type'];
      const countryCode =
        decoded['https://www.cochlear.com/country_code'];
      const locale = decoded.locale;
      const supportedCountry =
        countryCode && getSupportedCountry(countryCode) !== '';

      this.setState({
        firstName,
        lastName,
        name,
        userType,
        countryCode,
      });

      if (!supportedCountry) {
        // console.log('redirect ');
        this.setState({
          locale: locale,
          supportedCountry: false,
        });
        return false;
      }

      // const id = decoded.sub;
      const id =
        decoded && decoded['https://www.cochlear.com/cochlear_id'];
      return axios
        .get(`${contents}/menu?id=${id}&app=drx&lng=${lang}`, {
          params: {},
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          if (this._isMounted) {
            // from returned response
            const newToken =
              result.headers['x-amzn-remapped-authorization'];

            if (newToken) {
              this.updateUserToken(newToken);
            }
            this.onSetResult(result);
          }
        })
        .catch((error) => {
          if (this._isMounted) {
            const response = error.response;
            if ([401, 403].indexOf(response.status) !== -1) {
              authenticationService.renew();
              return false;
            }
            this.onErrorResult(error);
          }
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { isActiveProfile, showPopOver } = this.props;
    const {
      profileData,
      profileLoaded,
      logout,
      profileDataError,
      firstName,
      lastName,
      supportedCountry,
      countryCode,
      userType,
      locale,
      name,
    } = this.state;
    const {
      dropDownList,
      logoutLabel,
      initial,
      whatsnew,
      recipientText,
      carerText,
    } = profileData;

    // get text based on user type
    const getUserType = (userType) => {
      if (userType.toLowerCase() === 'carer') return carerText;
      return recipientText;
    };

    if (!supportedCountry) {
      return (
        <Redirect
          to={{
            pathname: `/${country}/${lang}/not-authorized`,
            state: {
              countryCode: countryCode,
              userType: userType,
              locale: locale,
            },
          }}
        />
      );
    }

    const ListItems = (props) => {
      // Logout gets returned with the last href link
      const linksLength = props.links.filter(
        (link) => link.url !== '',
      ).length;

      return props.links.map((link, index) => {
        if (link.url) {
          const isMCR = link.url.includes(
            '/wps/myportal/myrecipient/',
          );
          return (
            <React.Fragment key={link.url}>
              <a
                className={`profile-dropdown__menu__item ${
                  link.icon === 'external'
                    ? 'profile-dropdown__menu__item--external'
                    : ''
                }`}
                href={`${isMCR ? COCHLEAR_MCR : DRX_MAIN}${link.url}`}
                target={link.target || '_self'}
              >
                {link.label}
              </a>
              {/* // Logout gets positioned at the end of all the href links */}
              {index === linksLength - 1 && (
                <button
                  type="button"
                  className={`profile-dropdown__menu__item profile-dropdown__menu__item--logout has-spinner ${
                    logout ? 'is-submitting' : ''
                  }`}
                  disabled={logout}
                  onClick={this.handleLogout}
                >
                  {logoutLabel || 'Log out'}

                  {/* is submitting spinner  */}
                  <span className="lds-ring">
                    <span />
                    <span />
                    <span />
                    <span />
                  </span>
                </button>
              )}
            </React.Fragment>
          );
        }

        // if url doesnt exist, it is the new feature link
        if (this.state.profileData.whatsnew.toggle === 'on') {
          return (
            <button
              key={link.label}
              type="button"
              className="profile-dropdown__menu__item profile-dropdown__menu__item--new-feature"
              // onClick={this.toggleFeatures}
              onClick={() => {
                this.toggleFeatures();
                this.toggleClassDropdownNav();
              }}
            >
              {link.label}
            </button>
          );
        }

        return null;
      });
    };

    return (
      <div
        className={`profile-dropdown ${
          isActiveProfile ? 'show' : ''
        }`}
      >
        <button
          type="button"
          id="profileDropdownMenuButton"
          aria-haspopup="true"
          aria-expanded={isActiveProfile ? 'true' : 'false'}
          className="profile-dropdown__toggle"
          onClick={this.toggleClassDropdownNav}
        >
          {countryCode !== 'JP' ? (
            <span className="profile-dropdown__toggle__circle">
              {profileDataError
                ? `${firstName.charAt(0)}${lastName.charAt(0)}`
                : initial}
            </span>
          ) : (
            <span className="profile-dropdown__toggle__circle">
              <img
                src="https://assets.cochlear.com/api/public/content/46a7ac851fb24ab98378458c15106c03?v=a7e02268"
                alt="cochlear brandmark"
              />
            </span>
          )}
        </button>

        {showPopOver && whatsnew && (
          <div className="popover">
            <div className="popover__container">
              <button
                type="button"
                className="button button--popover"
                /* launches modal and closes popover */
                // eslint-disable-next-line arrow-parens
                onClick={() => {
                  this.toggleFeatures();
                  this.closePopOver();
                }}
              >
                {whatsnew.popup.label || "What's new in your profile"}
              </button>
              <button
                type="button"
                className="button button--dismiss"
                onClick={this.closePopOver}
              >
                {whatsnew.popup.close || 'Dismiss'}
              </button>
            </div>
          </div>
        )}

        <div
          className="profile-dropdown__menu"
          aria-labelledby="profileDropdownMenuButton"
        >
          <div className="profile-dropdown__menu__item profile-dropdown__menu__item--header">
            {!profileDataError &&
              (countryCode !== 'JP' ? (
                <React.Fragment>
                  <p className="subtitle">{name}</p>
                  <p className="account">
                    {getUserType(userType) || userType || 'Recipient'}
                  </p>
                </React.Fragment>
              ) : (
                <p className="subtitle">
                  {lastName}・{firstName}・
                  {getUserType(userType) || userType || 'Recipient'}
                </p>
              ))}
          </div>
          {/* Loop through dropdown list items */}
          {profileLoaded && <ListItems links={dropDownList} />}
        </div>
      </div>
    );
  }
}

export default withRouter(ProfileDropdown);
