import React from 'react';
import Slider from 'react-slick';
import {
  Image,
  Text,
  RichText,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import addRegToDevice from './../../utilities/addRegToDevice';
import config from './../../common/config';

const { country, lang } = config;

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      leftAligned: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
    this.dropdownMenu = React.createRef();
    this.dropdownContainer = React.createRef();
  }
  toggleClass(e) {
    const currentState = this.state.active;
    let l = e.currentTarget.offsetLeft;
    let w = this.dropdownMenu.current.clientWidth;
    let docW = this.dropdownContainer.current.clientWidth;

    // check if the offset and the width are smaller than the container width and right align if so unless....
    // if the left offset is equal to 0, left align the dropdown nav as a default
    const isEntirelyVisible = l + w <= docW || l === 0;

    this.setState({
      active: !currentState,
      leftAligned: isEntirelyVisible,
    });
  }

  render() {
    const settings = {
      dots: true,
      fade: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const { fields = {}, params = {}, rendering = {} } = this.props;

    const containsHtml =
      fields.highlightText !== undefined &&
      fields.highlightText.value !== ''
        ? true
        : false;
    // update the heading value
    if (containsHtml)
      fields.highlightText.value = addRegToDevice(
        fields.highlightText.value,
        params.trademarkRequired,
      );

    // The "Back" button in the hero
    // ------------------------------------------------
    const backLink = fields.internalLink?.value?.href ? (
      <div className="hero-links has-text-left">
        <div className="container">
          <Link
            data-ecategory="Hero Links"
            className={`${
              fields.internalLink.value.class || ''
            } button is-rounded include-icon-before`}
            to={`/${country}/${lang}${fields.internalLink.value.href}`}
          >
            <RichText tag="span" field={fields.linkText} />
          </Link>
        </div>
      </div>
    ) : null;

    // The "Category" icon in the hero
    // ------------------------------------------------
    const categoryIcon = fields.image?.value?.src && (
      <figure className="image is-64x64">
        <Image field={fields.image} />
      </figure>
    );

    // Build our hero title
    /* 
      You can maintain the normal spacing between titles and subtitles if you use the is-spaced modifier on the first element.
      Some hero components will include dropdowns, if the data contains label text for the dropdown, we'll hide this heading and show the other for non-mobile screens
    */
    let pageTitle = '';
    if (containsHtml && params.hasDropdown) {
      // we only show this if there is a highlight label and we are not on a mobile screen
      pageTitle = (
        <h1 className="title is-2 is-spaced">
          <Text className="" field={fields.headingWithHtml} />
          &nbsp;
          <span
            className={`dropdown hero--dropdown ${
              this.state.active ? 'is-active' : ''
            } ${this.state.leftAligned ? '' : 'is-right'}`}
            onClick={this.toggleClass}
          >
            <div className="dropdown-trigger">
              <button
                className="button is-text title is-2 include-icon-after icon-chevron"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
              >
                <RichText field={fields.highlightText} />
              </button>
            </div>
            <div
              className="dropdown-menu"
              id="dropdown-menu"
              role="menu"
              ref={this.dropdownMenu}
            >
              <div className="dropdown-content">
                <Placeholder
                  rendering={rendering}
                  name="jss-Dropdown"
                  renderEach={(component, index) => (
                    <React.Fragment key={index}>
                      {component}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
          </span>
          <br />
          <Text field={fields.headingWithHtmlAfter} />
        </h1>
      );
    } else if (containsHtml && !params.hasDropdown) {
      pageTitle = (
        <h1 className="title is-2 is-spaced">
          <Text field={fields.headingWithHtml} />{' '}
          <RichText
            tag="span"
            className="highlight"
            field={fields.highlightText}
          />
          <br className="is-hidden-mobile" />{' '}
          <Text
            tag="span"
            field={fields.headingWithHtmlAfter}
            style={{ whiteSpace: 'nowrap' }}
          />
        </h1>
      );
    } else {
      pageTitle = (
        <RichText
          tag="h1"
          className={`title ${
            params.wrapperClass === 'hero--home' ? 'is-1' : 'is-2'
          } is-spaced`}
          field={fields.heading}
        />
      );
    }

    /*  Slideshow */

    if (params.hasSlider) {
      return (
        <Slider {...settings}>
          {rendering.placeholders['jss-Slider'].map((item) => {
            // Hero content
            const text = (
              <div className="hero-body--slide--content">
                <RichText
                  tag="h1"
                  className={`title ${
                    params.wrapperClass === 'hero--home'
                      ? 'is-1'
                      : 'is-2'
                  } is-spaced`}
                  field={item?.fields?.heading}
                />
                <RichText
                  tag="h2"
                  className="subtitle is-4"
                  field={item?.fields?.content}
                />
                {item.fields?.button?.value?.href && (
                  <Link
                    to={`/${country}/${lang}${item.fields.button.value.href}`}
                    className="sdw__button sdw__button--cochlear-yellow"
                  >
                    {item.fields.button.value.text}
                  </Link>
                )}
              </div>
            );
            // Hero Slides
            return (
              <div
                className="hero-body hero-body--slide"
                key={item.uid}
              >
                <div
                  className="hero-body--slide--control"
                  style={{
                    backgroundColor:
                      item.fields?.backgroundColor?.value,
                  }}
                >
                  {/* Map through each breakpoints background image. This assumes each slide uses the same naming convention 
                  and has a background image per breakpoint (not including tablet) */}

                  {['Mobile', 'Desktop', 'Widescreen', 'FullHD'].map(
                    (size) => {
                      const imgSize = 'image' + size;
                      const spacerSize = 'spacer' + size;
                      const classSize = size.toLowerCase() + '-image';
                      return (
                        <div
                          className={`hero-body--slide--control--background-image ${classSize}`}
                          style={{
                            backgroundImage: `url(${item.fields && item.fields[imgSize].value?.src})`,
                          }}
                          key={size}
                        >
                          {/* Transparent spacer to maintain aspect ratio  */}
                          <Image
                            field={{
                              src: `${item.fields && item.fields[spacerSize].value?.src}`,
                            }}
                            className="hero-body--slide--control--spacer"
                          />
                          {/* Slider content, title, text, button, etc */}
                          <div className="hero-body--slide--content-wrapper">
                            {text}
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
            );
          })}
        </Slider>
      );
    }

    return (
      <React.Fragment>
        <div className={`hero-body ${params.wrapperClass}`}>
          {backLink}
          <div className="container" ref={this.dropdownContainer}>
            {/* category icon */}
            {categoryIcon}
            {pageTitle}
            <RichText
              tag="h2"
              className="subtitle is-4"
              field={fields.content}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Hero;
