const submitSchemaErrorMessages = {
	"required": {
    "firstName": "Please enter your first name",
    "lastName": "Please enter your last name",
    "countryDialingCode": "Select country code", 
    "mobilePhone": "Enter area code and number",
    "clinicName": "Please enter your clinic name",
    "clinicianName": "Please enter your audiologists name",
    "problemTopic": "Please select your topic",
    "problemDescription": "Please enter your problem",
    "addressLine": "Please enter your address",
    "city": "Please enter your city",
    "state": "Please select your state",
    "zipCode": "Please enter your zip code",
    "terms": "You must agree to the terms and conditions, and Privacy policy to continue."
  },
  "matches": {
    "mobilePhone": "Must be a valid area code and number",
    "zipCode": "Must be a valid zip code"
  },
  "max": {
    "addressLine": "Invalid field, maximum length is 35 characters including spaces"
  }
}

export default submitSchemaErrorMessages