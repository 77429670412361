import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const AuthorizationError = props => {
  // create img from api response and return properties
  function getImgProperties(str) {
    const newDiv = document.createElement('div');
    newDiv.innerHTML = str;
    return {
      src: newDiv.getElementsByTagName('img')[0].src,
      width: newDiv.getElementsByTagName('img')[0].width,
      alt: newDiv.getElementsByTagName('img')[0].alt,
    };
  }

  const { errorData, errorType, errorResponse, labels } = props;
  const { image, title, text } = errorData;
  const imageValue = image.value;
  // object values for the image
  const getImg = getImgProperties(imageValue);

  return (
    <div className="error-message__content">
      <figure className="error-message__image">
        <img
          src={getImg.src}
          alt={getImg.alt}
          style={{ width: getImg.width }}
        />
      </figure>
      <h2 className="error-message__title">{title.value}</h2>
      <p
        className="error-message__text"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(text.value),
        }}
      />

      {/* display error code */}
      {errorType === 'networkError' && errorResponse && (
        <p className="error-message__text error-message__text--code">
          <b>{`${labels.codeLabel}:`}</b>
          &nbsp;
          {errorResponse}
        </p>
      )}
    </div>
  );
};

AuthorizationError.propTypes = {
  errorType: PropTypes.string.isRequired,
  errorData: PropTypes.object.isRequired,
  errorResponse: PropTypes.string,
  labels: PropTypes.object.isRequired,
};

// Specifies the default values for props:
AuthorizationError.defaultProps = {
  errorResponse: 'Network error',
};

export default AuthorizationError;
