const dataDeviceListLables = {
  "hasOpenSR": "Device already submitted",
  "soundProcessor": "Sound Processor",
  "serialLabel": "Serial no",
  "warrantyLabel": "Warranty",
  "warrantyExpired": "Expired",
  "colorLabel": "Color",
  "noResultsLabel": "No results",
}

export default dataDeviceListLables