import React from 'react';
import { Link } from 'react-router-dom';
import config from './../../common/config';

const { country, lang } = config;
const RelatedIssues = (props) => (
  <li>
    <Link
      data-ecategory="Related Issues"
      to={`/${country}/${lang}${props.fields.heading.value.href}`}
      dangerouslySetInnerHTML={{
        __html: props.fields.heading.value.text,
      }}
    />
  </li>
);

export default RelatedIssues;
