import React from 'react';
import PropTypes from 'prop-types'; 
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import { Link } from 'react-router-dom';
import config from './../../common/config'

const Category = (props) => {
  const { country, lang } = config;
  const { fields, params } = props; 
  const device = params.forDevice || null;
  return (
    <Link data-ecategory="Problem Category" data-elabel={fields.title.value} to={`/${country}/${lang}/r/${device}${fields.internalLink.value.href}`}>
      <div className="media hover-grow">
        <div className="media-left">
          <figure className="image is-square">
            <Image media={fields.image} />
          </figure>
        </div>
        <div className="media-content">
          <Text tag="p" field={fields.title} />
          <Text tag="span" className="is-hidden-touch" field={fields.subtitle} />
        </div>
      </div>
    </Link>
  );
}

Category.propTypes = {
  fields: PropTypes.shape({
    /** Description of prop "image". */
    image: PropTypes.shape({
      value: PropTypes.object
    }),
    internalLink: PropTypes.shape({
      value: PropTypes.object
    }),
    title: PropTypes.shape({
      value: PropTypes.string
    }),
    subtitle: PropTypes.shape({
      value: PropTypes.string
    }),
  }),
  params: PropTypes.shape({
    // forDevice is a dynamically added param used to identify device category
    forDevice: PropTypes.string, 
  })
};

export default Category;



