import React from 'react';
import jwtDecode from 'jwt-decode';
import { Link, Redirect } from 'react-router-dom';
import {
  RichText,
  Text,
  Image,
} from '@sitecore-jss/sitecore-jss-react';

import config from './../../common/config';
import {
  authenticationService,
  environment,
} from './../../_services';
import AvailabilityStatus from './../custom/AvailabilityStatus/AvailabilityStatus';

const url = config.webToMail[environment].endpoint;

const Modal = (props) => {
  let textComment = React.createRef();
  let helpMessage = React.createRef();
  let submitBtn = React.createRef();

  function showModal(type) {
    let modal = document.getElementsByClassName('modal--' + type)[0];
    modal.classList.add('is-active');
    let html = document.documentElement;
    html.classList.add('is-clipped');
  }

  function hideModal(e) {
    e.preventDefault();
    modalReset();
  }

  function modalReset() {
    let modal = document.getElementsByClassName(
      'modal--' + props.params.type,
    )[0];
    modal.classList.remove('is-active');
    let html = document.documentElement;
    html.classList.remove('is-clipped');
  }

  function handleChange() {
    if (textComment.current.value.trim() !== '') {
      textComment.current.classList.remove('is-danger');
      helpMessage.current.classList.add('is-hidden');
    } else {
      textComment.current.classList.add('is-danger');
      helpMessage.current.classList.remove('is-hidden');
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (event.target.comment.value.trim() === '') {
      textComment.current.classList.add('is-danger');
      textComment.current.focus();
      helpMessage.current.classList.remove('is-hidden');
      return false; // validation, empty field
    }
    submitBtn.current.disabled = true; // stop user from making multiple submissions of the same comment
    submitBtn.current.classList.add('is-submitting');
    const data = {
      firstName: 'drxfeedback',
      lastName: 'null',
      email: 'null',
      inquiryReason: '1',
      country: 'US',
    };
    data.comment = `${
      event.target.comment.value
    } ${encodeURIComponent(window.location.href)}`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        return response.json();
      })
      .catch(function (error) {
        // console.log(error);
      })
      .then(function (data) {
        if (data.result === 'success') {
          submitBtn.current.classList.remove('is-submitting');
          submitBtn.current.disabled = false;
          document.getElementById('form').reset();
          hideModal(event);
          showModal('success');
        }
      });
  }

  // chat button trigger
  function handleClick(e) {
    e.preventDefault();

    // chatbot
    const findChatBtn = document.querySelectorAll(
      '.embeddedServiceHelpButton button.helpButtonEnabled',
    );

    if (window.embedded_svc && findChatBtn.length > 0)
      window.embedded_svc.liveAgentAPI.startChat();

    // miaw
    const findMiawButton = document.querySelectorAll(
      '.embedded-messaging',
    );

    if (window.embeddedservice_bootstrap && findMiawButton.length > 0)
      window.embeddedservice_bootstrap.utilAPI.launchChat();
  }

  function handleDisabled(e) {
    e.preventDefault();
  }

  function handleServiceRequestLink(e) {
    modalReset();
    // check if logged in
    const token = authenticationService.currentUserValue;
    const decoded = token && jwtDecode(token);

    const appAccess =
      decoded && decoded['https://www.cochlear.com/app'];
    const countryCode =
      decoded && decoded['https://www.cochlear.com/country_code'];
    const userType =
      decoded && decoded['https://www.cochlear.com/user_type'];
    const locale = decoded && decoded['locale'];
    // logged in user - check token exists and user has access
    if (token && appAccess && appAccess.includes('ds')) {
      return true;
    }

    // logged in user - NO DS CLAIM
    if (token && appAccess) {
      authenticationService.setReferrer('location', 'goBackUrl');
      return (
        <Redirect
          to={{
            pathname: `/${country}/${lang}/not-authorized`,
            state: {
              countryCode: countryCode,
              userType: userType,
              locale: locale,
            },
          }}
        />
      );
    }

    // not logged in
    // apply cookie for referrer as 'service-request' path
    // apply cookie for back/return button as current page url
    authenticationService.setReferrer('sr');
    authenticationService.setReferrer('location', 'goBackUrl');
    return true;
  }

  const { country, lang } = config;
  // text label strings
  const statusLabelOnlineSupport =
    props.onlineSupport === 'true' ? 'Available' : 'Offline';
  const statusLabelChat =
    props.chat === 'true' ? 'Online' : 'Offline';
  const statusLabelServiceRequests =
    props.serviceRequests === 'true' ? '' : 'Coming soon';
  return (
    <div className={`modal ${props.params.class}`}>
      {/* params.class and params.type need to uniquely match... using `class` allows us to use more than one styling class
      though which is important for modals with shared content, list content lists */}
      <div className="modal-background" onClick={hideModal}></div>
      <header className="modal-card-head">
        <button
          onClick={hideModal}
          className="delete modal-close is-larger"
          aria-label="close"
        ></button>
      </header>
      <div className="modal-card">
        <div className="modal-card-body">
          <figure className="modal-card-body__image">
            <Image field={props.fields.image} className="img-fluid" />
          </figure>
          <Text
            tag="h3"
            className="modal-card-title"
            field={props.fields.heading}
          />
          <RichText
            tag="p"
            className="modal-card-subtitle"
            field={props.fields.subheading}
          />

          {props.params.type === 'not-authorized' && (
            <div className="content">
              <div className="form__controls">
                <a
                  href="/"
                  onClick={hideModal}
                  className="close is-hidden-touch"
                >
                  <span className="delete is-large"></span>{' '}
                  {props.fields.closeButton.value}
                </a>
                <button
                  type="button"
                  className="sdw__button  sdw__button--has-no-icon sdw__button--cochlear-yellow-purple-text"
                  onClick={hideModal}
                >
                  {props.fields.submitButton.value}
                </button>
              </div>
            </div>
          )}

          {props.params.hasForm === 'true' ? (
            <div className="content">
              <form id="form" onSubmit={handleSubmit}>
                <textarea
                  id="comment"
                  name="comment"
                  className="textarea"
                  onChange={handleChange}
                  placeholder={props.fields.textAreaPlaceholder.value}
                  ref={textComment}
                ></textarea>
                <p
                  className="help is-danger is-hidden"
                  ref={helpMessage}
                >
                  This comment is invalid
                </p>
                <div className="form__controls">
                  <a
                    href="/"
                    onClick={hideModal}
                    className="close is-hidden-touch"
                  >
                    <span className="delete is-large"></span>{' '}
                    {props.fields.closeButton.value}
                  </a>
                  <button
                    type="submit"
                    className="sdw__button sdw__button--cochlear-yellow-purple-text"
                    ref={submitBtn}
                  >
                    {props.fields.submitButton.value}
                    {/* is submitting spinner  */}
                    <div className="lds-ring">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </form>
            </div>
          ) : props.params.type === 'success' ? (
            <a
              href="/"
              onClick={hideModal}
              className="sdw__button sdw__button--purple"
            >
              {props.fields.closeButton.value}
            </a>
          ) : props.params.type === 'thumbs-down' ||
            props.params.type === 'cant-see-my-device' ||
            props.params.type === 'lend-a-hand' ||
            props.params.type === 'device-not-supported' ||
            props.params.type === 'device-out-of-warranty' ||
            props.params.type === 'device-already-submitted' ? (
            <div className="content show-availability">
              <ul>
                <li>
                  <a
                    data-ecategory="Still having issue modal"
                    href={`tel:+${config.countryDialingCode}-${config.phoneNumber}`}
                    className={`include-icon-before icon-call ${
                      props.onlineSupport === 'true'
                        ? 'is-online'
                        : 'is-offline'
                    }`}
                    disabled={props.onlineSupport === 'false'}
                    onClick={
                      props.onlineSupport === 'false'
                        ? handleDisabled
                        : undefined
                    }
                  >
                    Call customer support on{' '}
                    <span className="phone">
                      {config.phoneNumber}
                    </span>
                    <AvailabilityStatus
                      label={statusLabelOnlineSupport || ''}
                      showLabel={true}
                      class={
                        props.onlineSupport === 'true'
                          ? 'is-online'
                          : 'is-offline'
                      }
                    />
                  </a>
                </li>
                <li>
                  <a
                    data-ecategory="Still having issue modal"
                    href="/"
                    className={`include-icon-before include-icon-after icon-chat ${
                      props.chat === 'true'
                        ? 'is-online'
                        : 'is-offline'
                    }`}
                    disabled={props.chat === 'false'}
                    onClick={
                      props.chat === 'false'
                        ? handleDisabled
                        : handleClick
                    }
                  >
                    Chat online with a customer support member
                    <AvailabilityStatus
                      label={statusLabelChat || ''}
                      showLabel={true}
                      class={
                        props.chat === 'true'
                          ? 'is-online'
                          : 'is-offline'
                      }
                    />
                  </a>
                </li>
                {(props.params.type === 'thumbs-down' ||
                  props.params.type === 'cant-see-my-device') && (
                  <li>
                    <Link
                      data-ecategory="Still having issue modal"
                      to={`/${country}/${lang}/service-request`}
                      className={`include-icon-before include-icon-after icon-service icon-link ${
                        props.serviceRequests === 'true'
                          ? ''
                          : 'is-coming-soon'
                      }`}
                      disabled={props.serviceRequests === 'false'}
                      onClick={
                        props.serviceRequests === 'false'
                          ? handleDisabled
                          : handleServiceRequestLink
                      }
                    >
                      Submit your device for repair
                      {props.serviceRequests === 'false' && (
                        <div className="availability-status availability-status--is-coming-soon">
                          {statusLabelServiceRequests}
                        </div>
                      )}
                    </Link>
                  </li>
                )}

                {/* New Item for Upgrading */}

                <li>
                  <a
                    data-ecategory="Still having issue modal"
                    href="https://www.cochlear.com/us/en/shop/sound-processors/nucleus/nucleus-8-sound-processor-upgrade/UPGRADE-NUCLEUS8-FCM607.html"
                    className="include-icon-before icon-upgrade include-icon-after icon-external"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Upgrade to the Nucleus<sup>&reg;</sup> 8 Sound
                    Processor
                  </a>
                </li>

                <li>
                  <a
                    data-ecategory="Still having issue modal"
                    href="https://www.cochlear.com/us/en/connect/contact-us/contact-us-recipient"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="include-icon-before icon-enquiry include-icon-after icon-external"
                  >
                    Make an online inquiry
                  </a>
                </li>
                {(props.params.type === 'thumbs-down' ||
                  props.params.type === 'cant-see-my-device') && (
                  <li>
                    <a
                      data-ecategory="Still having issue modal"
                      href="https://www.cochlear.com/us/en/home/ongoing-care-and-support/device-support"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="include-icon-before icon-user-guides include-icon-after icon-external"
                    >
                      Visit device support to get videos, user guides
                      and more
                    </a>
                  </li>
                )}
              </ul>
            </div>
          ) : (
            <RichText field={props.fields.content} />
          )}
        </div>
      </div>
      {props.params.type !== 'success' && (
        <footer className="modal-card-foot">
          <a href="/" onClick={hideModal} className="close">
            <span className="delete is-larger"></span> Close this
            window
          </a>
        </footer>
      )}
    </div>
  );
};

export default Modal;
