import React from 'react';
import {
  Image,
  RichText,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import config from './../../common/config';

const { country, lang } = config;

const ArticlesSection = props => {
  const { fields, params, rendering } = props;
  return (
    <div className={params.wrapperClass}>
      <div className="columns is-gapless">
        <div
          className="column column--bgImage is-one-third-tablet is-half-desktop is-three-quarters-widescreen"
          style={{
            backgroundImage: `url(${fields.image.value.src})`,
          }}
        >
          <figure className="image is-hidden-tablet">
            <Image media={fields.imageMobile} />
          </figure>
        </div>
        <div className="column is-two-thirds-tablet is-three-quarters-desktop">
          <div className="column__padding">
            <RichText
              tag="h2"
              className="title is-size-4-mobile"
              field={fields.heading}
            />
            <Placeholder
              rendering={rendering}
              name="jss-ArticlesSection"
              renderEach={(component, index) => {
                if (index >= 5) return null;
                if (index >= 3) {
                  component.props.params.isHiddenMobile = true;
                }
                return (
                  <React.Fragment key={index}>
                    {component}
                  </React.Fragment>
                );
              }}
            />
          </div>
          <div className="column--footer">
            <Link
              to={`/${country}/${lang}${fields.internalLink.value.href}`}
            >
              {fields.internalLink.value.text}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlesSection;
