import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from './../../../common/config';
import { environment } from './../../../_services';

const { country, lang, authenticate } = config;
const contentsAPI = authenticate[environment].contents;

function menuLink(item) {
  if (item) {
    const { href, text, target } = item;

    // some links are relative in the api
    const path =
      href.substring(0, 4) !== 'http'
        ? `https://www.cochlear.com${href}`
        : href;

    return (
      <li key={path}>
        <a
          data-ecategory="Footer Links"
          href={path}
          target={target}
          rel="noopener noreferrer"
        >
          {text}
        </a>
      </li>
    );
  }
  return null;
}

function FooterLinks() {
  const [footerLinks, setFooterLinks] = useState({});
  const [footerSocialLinks, setFooterSocialLinks] = useState([]);
  const [copyright, setCopyright] = useState('');

  useEffect(() => {
    let mounted = true;
    axios
      .get(
        `${contentsAPI}/header-footer?lng=${lang}&country=${country}`,
      )
      .then((res) => {
        if (mounted) {
          setFooterLinks(res.data.footerLinks);
          setFooterSocialLinks(res.data.footerSocialLinks);
          setCopyright(res.data.footerCopyright);
        }
      })
      .catch((err) => {
        if (mounted) {
          // console.log(err);
        }
      });

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <section className="footer__cochlear">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-9-tablet is-11-desktop">
              <div className="columns is-desktop">
                <div className="column is-narrow is-paddingless">
                  <figure className="image cochlear-logo">
                    <img
                      src="https://assets.cochlear.com/api/public/content/05b1708cae8a4fa380fb5ac738b24d1e?v=2ea172c6"
                      alt="Cochlear"
                    />
                  </figure>
                </div>
                <div className="column">
                  <ul className="footer__cochlear__links">
                    <li className="col-a">
                      <ul>
                        <li className="col-a-a">
                          <ul>
                            {footerLinks &&
                              footerLinks.column1 &&
                              footerLinks.column1.map((footerLink) =>
                                menuLink(footerLink),
                              )}
                          </ul>
                        </li>
                        <li className="col-a-b">
                          <ul>
                            {footerLinks &&
                              footerLinks.column2 &&
                              footerLinks.column2.map((footerLink) =>
                                menuLink(footerLink),
                              )}
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className="col-b">
                      <ul>
                        {footerLinks &&
                          footerLinks.column3 &&
                          footerLinks.column3.map((footerLink) =>
                            menuLink(footerLink),
                          )}
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="column is-narrow">
                  <ul className="social-media">
                    {footerSocialLinks &&
                      footerSocialLinks.map((socialLink) => {
                        return (
                          <li key={socialLink.link.href}>
                            <a
                              data-ecategory="Footer Links"
                              href={socialLink.link.href}
                              title={socialLink.link.text}
                              target={socialLink.link.target}
                              rel="noopener noreferrer"
                            >
                              <span
                                className="social-media__icon"
                                style={{
                                  backgroundImage: `url(${socialLink.icon.src})`,
                                }}
                              >
                                <span className="is-sr-only">
                                  {socialLink.link.text}
                                </span>
                              </span>
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="has-background-dark footer__copyright">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-11-desktop">
              <div className="has-text-centered has-text-right-tablet">
                <p>{copyright}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterLinks;
