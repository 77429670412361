import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const Dropdown = (props) => {
  return (
    <Placeholder 
      rendering={props.rendering} 
      name="jss-Dropdown" 
      renderEach={(component, index) => (
        <React.Fragment key={index}>
          {component}
        </React.Fragment>
      )}
    />
  );
}

export default withSitecoreContext()(Dropdown);