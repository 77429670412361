import React from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import jwtDecode from 'jwt-decode';

import { authenticationService, environment } from './_services';
import componentFactory from './temp/componentFactory';
import SitecoreContextFactory from './lib/SitecoreContextFactory';
import RouteHandler from './RouteHandler';
import NotAuthorized from './NotAuthorized';
import ScrollToTop from './ScrollToTop';

import { history } from './_helpers';
import config from './common/config';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:country([a-z]{2})/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:country([a-z]{2})/:lang([a-z]{2})/:sitecoreRoute*',
  // '/:sitecoreRoute*',
];

// all these should route to the home page
export const redirectPatterns = [
  '/',
  '/home',
  '/home/r',
  '/:country([a-z]{2})',
  '/:country([a-z]{2})/home',
  '/:country([a-z]{2})/home/r',
  '/:country([a-z]{2})/:lang([a-z]{2}-[A-Z]{2})',
  '/:country([a-z]{2})/:lang([a-z]{2})',
  '/:country([a-z]{2})/:lang([a-z]{2}-[A-Z]{2})/home/r',
  '/:country([a-z]{2})/:lang([a-z]{2}-[A-Z]{2})/r',
  '/:country([a-z]{2})/:lang([a-z]{2})/home/r',
  '/:country([a-z]{2})/:lang([a-z]{2})/r',
];

// all these should route to service requests
export const redirectPatternsSr = [
  '/:country([a-z]{2})/:lang([a-z]{2}-[A-Z]{2})/service-request',
  '/:country([a-z]{2})/:lang([a-z]{2})/service-request',
  '/service-request',
];

const { country, lang, authenticate } = config;
const authAPI = authenticate[environment].authorization;

const PrivateRoute = React.forwardRef((props, ref) => {
  const token = authenticationService.currentUserValue;
  const decoded = token && jwtDecode(token);
  const appAccess =
    decoded && decoded['https://www.cochlear.com/app'];
  const countryCode =
    decoded && decoded['https://www.cochlear.com/country_code'];
  const userType =
    decoded && decoded['https://www.cochlear.com/user_type'];
  const locale = decoded && decoded.locale;
  const { srComponent } = props;

  // check token exists and user has access
  if (token && appAccess && appAccess.includes('ds')) {
    return routePatterns.map((routePattern) => (
      <Route
        key={routePattern}
        path={routePattern}
        render={srComponent}
        ref={ref}
        currentUser={token}
        userId={decoded['https://www.cochlear.com/cochlear_id']}
      />
    ));
  }

  // NO DS CLAIM
  if (token && appAccess) {
    return (
      <Redirect
        to={{
          pathname: `/${country}/${lang}/not-authorized`,
          state: {
            countryCode: countryCode,
            userType: userType,
            locale: locale,
          },
        }}
      />
    );
  }

  // Not logged in so redirect to login page
  // set this location as the referrer url in cookie
  authenticationService.setReferrer();

  return (
    <Route
      path="/"
      component={() => {
        // window check - server will fail without it
        if (typeof window !== 'undefined') {
          window.location.href = `${authAPI}/authorize?app=ds`;
          return null;
        }
        return null;
      }}
    />
  );
});

// switch authentication endpoints for local development
// to get callback urls from lambda service set process environement to LOCAL at runtime
// $ REACT_APP_TEST_VAR=LOCAL jss start
// if REACT_APP_TEST_VAR=LOCAL is true, config.authenticate calls different endpoint for local development

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
//const AppRoot = ({ path, Router, graphQLClient }) => {
class AppRoot extends React.Component {
  mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      sid: null,
      userId: null,
      currentUser: null,
    };
    // ref passed through to logout function
    // if logging out from a private path, the logout URL callback is different
    this.privatePath = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      this.setUserDetails(authenticationService.currentUserValue);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setUserDetails(user) {
    const { sid } = this.state;
    const currentUser = user;
    if (currentUser) {
      const decoded = jwtDecode(currentUser);
      if (decoded.sid !== sid) {
        this.setState({
          sid: decoded.sid,
          userId: decoded['https://www.cochlear.com/cochlear_id'],
          currentUser,
        });
      }
    }
  }

  render() {
    const { path, Router, graphQLClient } = this.props;
    const { userId, currentUser } = this.state;

    const routeRenderFunction = (props) => {
      return (
        <RouteHandler
          // Convert everything defined as a `home` to redirect to '/' whilst maintaining the url path
          route={{
            ...props,
            ...(props.match.params.sitecoreRoute === 'home' && {
              match: { params: { sitecoreRoute: '/' } },
            }),
          }}
          currentUser={currentUser}
          userId={userId}
          privatePath={this.privatePath}
        />
      );
    };

    return (
      <ApolloProvider client={graphQLClient}>
        <SitecoreContext
          componentFactory={componentFactory}
          contextFactory={SitecoreContextFactory}
        >
          <Router location={path} context={{}} history={history}>
            <ScrollToTop>
              <Switch>
                <Route
                  exact
                  path={`/${country}/${lang}/not-authorized`}
                  component={NotAuthorized}
                />
                {redirectPatternsSr.map((redirectPatternSr) => (
                  <PrivateRoute
                    key={redirectPatternSr}
                    path={redirectPatternSr}
                    srComponent={routeRenderFunction}
                    ref={this.privatePath}
                  />
                ))}
                {redirectPatterns.map((redirectPattern) => {
                  return (
                    <Redirect
                      exact
                      key={redirectPattern}
                      from={redirectPattern}
                      to={`/${country}/${lang}/home`}
                    />
                  );
                })}

                {routePatterns.map((routePattern) => (
                  <Route
                    key={routePattern}
                    path={routePattern}
                    render={routeRenderFunction}
                  />
                ))}
              </Switch>
            </ScrollToTop>
          </Router>
        </SitecoreContext>
      </ApolloProvider>
    );
  }
}

export default AppRoot;
