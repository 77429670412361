import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const Slider = (props) => {
  return (
    <Placeholder
      rendering={props.rendering}
      name="jss-Slider"
      renderEach={(component, index) => (
        <React.Fragment key={index}>{component}</React.Fragment>
      )}
    />
  );
};

export default withSitecoreContext()(Slider);
