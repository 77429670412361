import React from 'react';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import ProfileDropdown from './components/custom/ProfileDropdown/ProfileDropdown';
import CountryLanguage from './components/custom/CountryLanguage/CountryLanguage';

import config from './common/config';
import { environment } from './_services/environment';
import getMinutes from './utilities/getMinutes';

const { country, authenticate, cookieDomain, baseURL } = config;
const contentsAPI = authenticate[environment].contents;
const authAPI = authenticate[environment].authorization;
const domainValue = cookieDomain[environment];
const getDeviceSupportPath = baseURL[environment];

function NavbarItem({
  isActive,
  isPrimary,
  label,
  link,
  target,
  icon,
}) {
  const path =
    link && link.substring(0, 4) !== 'http'
      ? `https://www.cochlear.com${link}`
      : link;

  const newLabel = label === 'Home' ? 'Cochlear.com' : label;

  return (
    <a
      href={path}
      target={target || '_self'}
      className={`navbar-item ${isActive ? 'is--active' : ''} ${
        isPrimary ? 'is--primary' : ''
      }`}
    >
      {icon && <i className={`drxi drxi--${icon}`} />}
      {newLabel}
    </a>
  );
}

function primaryHeader(items) {
  const values = items && Object.values(items);
  return values.map((value) => (
    <NavbarItem
      key={value.href}
      label={value.text}
      isPrimary
      link={value.href}
    />
  ));
}

const linksLoop = (items) => {
  return (
    items &&
    items.map((item) => {
      const { href, text, target } = item;
      let isActive = false;
      let pathUrl = href;
      // check the link is for device support and env not prd
      if (environment !== 'PRD') {
        if (pathUrl.includes('https://support.cochlear.com/')) {
          pathUrl = getDeviceSupportPath;
          isActive = true;
        }
      }
      return (
        <NavbarItem
          key={pathUrl}
          link={pathUrl}
          label={text}
          target={target}
          isActive={isActive}
        />
      );
    })
  );
};

class UniversalHeader extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      headerNavigation: [],
      headerLinks: [],
    };
    this.callbackWhatsnew = this.callbackWhatsnew.bind(this);
    this.callbackWhatsnewContent =
      this.callbackWhatsnewContent.bind(this);
    this.callbackShowPopOver = this.callbackShowPopOver.bind(this);
  }

  callbackWhatsnew() {
    this.props.callbackWhatsnew();
  }
  callbackWhatsnewContent(data) {
    this.props.callbackWhatsnewContent(data);
  }
  callbackShowPopOver(bool) {
    this.props.callbackShowPopOver(bool);
  }

  componentDidMount() {
    this._isMounted = true;

    const { routeParams } = this.props;

    axios
      .get(
        `${contentsAPI}/header-footer?lng=${routeParams?.lang}&country=${country}`,
      )
      // .then(response => response.json())
      .then((response) => {
        if (this._isMounted) {
          const { data } = response;

          // jsonData is parsed json object received from url
          this.setState({
            headerNavigation: data['headerMainNavigation'],
            headerLinks: data['headerLinks'],
          });
          // pass the header login details to Layout
          // links to be shared with Navigation Component
          this.props.onHeaderLoginMounted(
            data['loginLink'],
            !!(
              data['features'].universalHeader &&
              data['features'].universalHeader === true
            ),
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          // handle your errors here
          // console.error(error);
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      currentUser,
      privatePath,
      headerLogin,
      isProfileEnabled,
      callbackWhatsnew,
      callbackWhatsnewContent,
      callbackShowPopOver,
      showPopOver,
      routeParams,
    } = this.props;

    const { headerNavigation, headerLinks } = this.state;

    const decoded = currentUser && jwtDecode(currentUser);
    const appAccess =
      decoded && decoded['https://www.cochlear.com/app'];
    const loggedIn = appAccess;

    // update the header login path based on environemnt
    if (environment !== 'PRD') {
      if (isProfileEnabled) {
        headerLogin.href = `${authAPI}/authorize?app=ds`;
      }
    }

    return (
      <nav
        className="navbar navbar--universal is-hidden-touch"
        role="navigation"
        aria-label="universal navigation"
      >
        <div className="container">
          <div className="navbar-menu navbar-menu--primary">
            <div className="navbar-start">
              {primaryHeader(headerNavigation)}
            </div>
            <div className="navbar-end">
              {/* Links that appear in the main navbar. */}
              {linksLoop(headerLinks)}
              <div className="navbar-item">
                <CountryLanguage
                  country={country}
                  language={routeParams?.lang || 'en'}
                />
              </div>
              {loggedIn ? (
                <ProfileDropdown
                  isActiveProfile={this.props.isActiveProfile}
                  onChildClick={this.props.onChildClick}
                  privatePath={privatePath}
                  callbackWhatsnew={callbackWhatsnew}
                  callbackWhatsnewContent={callbackWhatsnewContent}
                  callbackShowPopOver={callbackShowPopOver}
                  showPopOver={showPopOver}
                  lang={routeParams?.lang || 'en'}
                />
              ) : (
                // confirm header-footer has a login link
                headerLogin &&
                headerLogin.href && (
                  <div className="navbar-item">
                    <a
                      href={headerLogin.href}
                      className="button is-link"
                      onClick={() => {
                        // Set referrerUrl as a cookie as it needs to be shared across sub domains
                        return Cookies.set(
                          'referrerUrl',
                          window.location.href,
                          {
                            path: '/',
                            domain: domainValue,
                            secure: true,
                            expires: getMinutes(15),
                          },
                        );
                      }}
                    >
                      {headerLogin.title || 'Login'}
                    </a>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default UniversalHeader;
