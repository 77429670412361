import React from "react"
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Field, ErrorMessage } from 'formik'
import Select from 'react-select'

import EditButton from './../EditButton/EditButton'
import FlagItem from './../../FlagItem/FlagItem'

import countryList from './../../../../utilities/countryList'
import isEmpty from './../../../../utilities/isEmptyObject'


// country code options list
const options = [];
countryList.map(obj => { 
  options.push({
    'value' : obj.dial_code,
    'label' : <FlagItem code={obj.code.toLowerCase()} name={obj.name} dial_code={obj.dial_code} />
  })
  return obj;
})


// Country code dropdown
class MySelect extends React.Component {

  handleChange = value => {
    // this is going to call setFieldValue and manually update values.countryDialingCode
    this.props.onChange('countryDialingCode', value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.countryDialingCode
    this.props.onBlur('countryDialingCode', true);
  };

  render() {        
    const { value, label, selectLabel } = this.props;
    return (
      <div className={`field field--code ${(this.props.error && this.props.touched) ? 'has--error' : ''}`}>                
        <label htmlFor="countryDialingCode" className="label">
        <Text field={label} />
        </label>                      
        <div className="control">
          <div className="select" style={{width: '100%'}}>
            <Select
              placeholder={selectLabel.value}
              id="countryDialingCode"
              filterPos="start"
              filterProp="value"
              options={options}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={value}
              className='react-select-container'
              classNamePrefix="react-select"
            />
          </div>
        </div>
        {!!this.props.error &&
          this.props.touched && (
            <div className="error__message">{this.props.error}</div>
          )}
      </div>
    );
  }
}

class Step2 extends React.Component {

  constructor(props) {
    // fires before component is mounted
    super(props); // makes this refer to this component
    this.state = {}; // set state
  }

  render() {
    const { 
      values, 
      id, 
      currentStep, 
      handleNext, 
      touched, 
      errors, 
      validateForm, 
      setTouched, 
      setFieldValue, 
      setFieldTouched, 
      title, 
      labels,
      selectLabel,
      nextBtnLabel,
      handleEdit,  
      isValid
    } = this.props;

    const isCurrentStep = currentStep < 2 ? "has-text-future" : "";
    
    return (
      <li className="step__panel" id={id}>
        <h2 className={`${isCurrentStep} title is-3`}>
          <Text field={title} />
          {currentStep > 2 &&
            <EditButton handleEdit={handleEdit} validateForm={validateForm} currentStep={currentStep} thisStep={2} label={this.props.edit} />
          }           
        </h2>
        {currentStep === 2 &&
          <fieldset className="step__panel__inner" id={`fieldset__${currentStep}`}>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className={`field ${(errors.firstName && touched.firstName) ? 'has--error' : ''}`}>
                  <label htmlFor="firstName" className="label">
                    <Text field={labels.formLabelFirstName} />
                  </label>
                  <div className="control">
                    <Field
                      className="input"
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder={labels.formLabelFirstName.value}
                      required
                    />
                  </div>
                  <ErrorMessage name="firstName" render={msg => <div className="error__message">{msg}</div>} />
                </div>

                <div className={`field ${(errors.lastName && touched.lastName) ? 'has--error' : ''}`}>
                  <label htmlFor="lastName" className="label">
                    <Text field={labels.formLabelLastName} />
                  </label>
                  <div className="control">
                    <Field
                      className="input"
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder={labels.formLabelLastName.value}
                      required
                    />
                  </div>
                  <ErrorMessage name="lastName" render={msg => <div className="error__message">{msg}</div>} />
                </div>       
              </div>
            </div>
            <div className="field field--email">
              <label htmlFor="email" className="label">
              <Text field={labels.formLabelEmail} />
              </label>
              <div className="control">
                <Field
                  className="input"
                  id="email"
                  name="email"
                  type="email"
                  placeholder={labels.formLabelEmail.value}
                  required
                  readOnly
                />
              </div>
            </div>          

            <div className="field is-horizontal field--mobile-group">
              <div className="field-body">
                
                <div className="field is-narrow field--type">
                  <label htmlFor="numberType" className="label">
                    <Text field={labels.formLabelNumberType} />
                  </label>                      
                  <div className="control">
                    <div className="select is-readonly" style={{width: '100%'}}>
                      <Field
                        component="select"
                        id="numberType"
                        name="numberType"
                        defaultValue={labels.formLabelNumberTypeOption1.value}
                        required
                        readOnly
                      >
                        <option disabled value={labels.formLabelNumberTypeOption1.value}>{labels.formLabelNumberTypeOption1.value}</option>
                      </Field>
                    </div>
                  </div>
                </div>
                      
                <MySelect
                  value={values.countryDialingCode}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={errors.countryDialingCode}
                  touched={touched.countryDialingCode}
                  label={labels.formLabelCountryDialingCode}
                  selectLabel={selectLabel}
                />

                <div className={`field field--number ${(errors.mobilePhone && touched.mobilePhone) ? 'has--error' : ''}`}> 
                  <label htmlFor="mobilePhone" className="label">
                    <Text field={labels.formLabelCountryMobilePhone} />
                  </label>                      
                  <div className="control">
                    <Field
                      className="input"
                      id="mobilePhone"
                      name="mobilePhone"
                      type="tel"
                      placeholder={labels.formLabelCountryMobilePhone.value}
                      maxLength="15"
                      required
                    />
                  </div>
                  <ErrorMessage name="mobilePhone" render={msg => <div className="error__message">{msg}</div>} />
                </div>
              </div>   
            </div>         
            <br />
            <button data-ecategory="SR contact details" type="button" className={`sdw__button sdw__button--cochlear-yellow ${(isEmpty(errors) && isValid) ? '' : 'sdw__button--disabled'}`}
              disabled={!isEmpty(errors) || !isValid}
              onClick={() => validateForm().then(validation => {
                setTouched(validation)
                if (isEmpty(validation)) {
                  handleNext(validateForm)
                }
              })}
            >
              <Text field={nextBtnLabel} />
            </button>
          </fieldset>
        }
        {currentStep > 2 &&
          <div className="step__panel__inner">
            <p>{values.firstName} {values.lastName}</p>
            <p>{values.countryDialingCode.value} {values.mobilePhone}</p>
            <p>{values.email}</p>
          </div>
        }
      </li>
    );
  }
}

export default Step2;
