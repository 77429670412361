import React from 'react';
import PropTypes from 'prop-types';
import 'flag-icon-css/css/flag-icon.css'

const FlagItem = (props) => {
  return (
    <React.Fragment>
      <span className={`flag-icon flag-icon-${props.code}`}></span>
      <span className="name">{props.name}</span>&nbsp;<b>{props.dial_code}</b>
    </React.Fragment>
  )
}

// Specifies the default values for props:
FlagItem.defaultProps = {
  code: '',
  name: '',
  dial_code: ''
};

FlagItem.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dial_code: PropTypes.string.isRequired,
};

export default FlagItem